import React, { Component } from 'react'
import Service from "../Networking/networkutils";
import { getCookie } from '../Helper/CookieHelper';
import { withSnackbar } from 'notistack';
import MaterialTable from 'material-table';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    TextField,
    Tooltip,
    Typography,
    // Tooltip 
} from '@material-ui/core';
import LoopCircleLoading from 'react-loadingg/lib/LoopCircleLoading';
import { errorMessage } from '../Helper/ErrorMessage';
// import { CsvBuilder } from 'filefy';
import moment from "moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import TaskHoldDetail from './TaskHold/TaskHoldDetail';
// import TaskLevelTabs from './TaskLevelAllocation/TaskLevelTabs';
import WorkIcon from '@material-ui/icons/Work';
import SubContractLevelTabs from './SubContractActual/SubContractLevelTabs';
import { getPrimaryColor } from '../Helper/CommonThings';
import { CsvBuilder } from 'filefy';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import WebIcon from '@material-ui/icons/Web';
import MainTenderSubTasks from '../TenderSubTasks/MainTenderSubTasks';
import { CoffeeLoading } from 'react-loadingg';
import { Alert } from '@material-ui/lab';
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

import Allocation from '../Allocation/Allocation';
// import { green } from '@material-ui/core/colors';
// import {withStyles } from '@material-ui/core/styles';
import Slide from '@material-ui/core/Slide';


const token = getCookie("bb_ts_token");
const access = JSON.parse(getCookie("bb_ts_access"));
const user = JSON.parse(getCookie("bb_ts_user"));

var triggerRefresh = false

// const GreenButton = withStyles((theme) => ({
//     root: {
//     //   color: theme.palette.getContrastText(green[500]),
//     //   backgroundColor: green[500],
//     //   '&:hover': {
//     //     backgroundColor: green[700],
//     //   },
//     },
//     selected :{
//         color: theme.palette.getContrastText(green[500]),
//       backgroundColor: green[500],
//       '&:hover': {
//         backgroundColor: green[700],
//       },
//     }
//   }))(Button);

const c_green = {
    button: {
        borderRadius: 2,
        color: '#66BB6A',
        minWidth: '50px',
        border: '1px solid #66BB6A',
    },
    fllled: {
        background: '#66BB6A',
        color: '#000',
    },
}
const c_yellow = {
    button: {
        borderRadius: 2,
        color: '#FFC107',
        minWidth: '50px',
        border: '1px solid #FFC107',
    },
    fllled: {
        background: '#FFC107',
        color: '#000',
    },
}
const c_orange = {
    button: {
        borderRadius: 2,
        color: '#FF5722',
        minWidth: '50px',
        border: '1px solid #FF5722',
    },
    fllled: {
        background: '#FF5722',
        color: '#000',
    },
}
//   const c_red = {
//     button: {
//         background: 'white',
//         borderRadius: 2,
//         color: '#F44336',
//         padding: '5px',
//         minWidth:'30px',
//         border:'1px solid #F44336',
//         width :65
//       },
//     fllled: {
//       background: '#F44336',
//       color:'#000',
//     },
//   }
const c_blueGrey = {
    button: {
        borderRadius: 2,
        color: '#00BCD4',
        minWidth: '50px',
        border: '1px solid #00BCD4',
    },
    fllled: {
        background: '#00BCD4',
        color: '#000',
    },
}

var postAPIDataBackup = null

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
class AllTenderData extends Component {

    constructor(props) {
        super(props)
        // console.log(props)
        this.state = {
            columns: [],
            isLoading: true,
            openPDM: false,
            data: [],
            allRoles: {},
            openHold: false,
            holdDate1: new Date(),
            holdDate2: new Date(),
            holdComment: "",
            holdData: {},
            fullHoldData: [],
            holdType: 0,
            openHoldDetail: false,
            openUnhold: false,
            get_acthours: [],
            get_assignusers: [],
            openUnholdImmediate: false,
            filterStatus: '0',
            rates: [],
            fullData: [],
            usersData: null,
            billData: null,
            openBillable: false,
            openCompleteTask: false,
            firstCompletionChange: null,
            openSubTender: false,
            subTenderData: null,
            openCoffee: false,
            delayDateItems: [],
            NonDelayItems: [],
            nounsLookup: null,
            adjectiveLookup: null,
            delayDateDialog: false,
        }
    }


    componentDidMount() {
        // alert(window.innerHeight)
        // Service.get(`/bb/api/tender/user/task/?pid=` + this.props.value.id, {
        //     headers: {
        //         Authorization: "Token " + token,
        //     },
        // })
        //     .then(res => {
        //         // console.log(res.data)

        //         this.fetchData(res.data);

        //     })
        //     .catch(error => {
        //         this.props.enqueueSnackbar('Something went wrong!', {
        //             variant: 'error'
        //         });
        //     });
        Service.get(`/bb/api/users/`, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res5 => {

                this.setState({ usersData: res5.data, })

            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
        this.fetchData();
        this.fetchNounsAndAdjectives()
    }

    fetchNounsAndAdjectives() {
        Promise.all([
            this.getNouns(),
            this.getAdjectives()
        ])
            .then(res => {
                let nounsLookup = {}
                let adjectiveLookup = {}
                for (let i = 0; i < res[0].length; i++) {
                    const element = res[0][i];
                    nounsLookup[element.id] = element.name
                }
                for (let i = 0; i < res[1].length; i++) {
                    const element = res[1][i];
                    adjectiveLookup[element.id] = element.name
                }
                this.setState({ nounsLookup, adjectiveLookup })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Something went wrong!', {
                    variant: 'error'
                })
            })
    }

    getNouns() {
        return new Promise((resolve, reject) => {
            Service.get("/bb/api/task/date/change/nouns/", {
                headers: {
                    Authorization: "Token " + token,
                },
            }).then(res => resolve(res.data)).catch(error => reject(error))
        })
    }

    getAdjectives() {
        return new Promise((resolve, reject) => {
            Service.get("/bb/api/task/date/change/adjective/", {
                headers: {
                    Authorization: "Token " + token,
                },
            }).then(res => resolve(res.data)).catch(error => reject(error))
        })
    }

    getActualHours(ttask, pid) {
        Service.patch("/bb/api/team/tasks/get_acthours/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: pid,
        })
            .then(res12 => {

                for (let j = 0; j < res12.data.length; j++) {
                    const element13 = res12.data[j];
                    for (let i = 0; i < ttask.length; i++) {
                        const element12 = ttask[i];
                        if (element12.id === element13.id) {
                            element12.actual_hours = timeToDecimal(element13.hours_actual)
                            break;
                        }
                        // console.log(timeToDecimal(element13.hours_actual) !== 0)
                    }

                }
                for (let j = 0; j < res12.data.length; j++) {
                    const element13 = res12.data[j];
                    for (let i = 0; i < this.data.length; i++) {
                        const element12 = this.data[i];
                        if (element12.id === element13.id) {
                            element12.actual_hours = timeToDecimal(element13.hours_actual)
                            break;
                        }
                        // console.log(timeToDecimal(element13.hours_actual) !== 0)
                    }

                }
                // console.log(dataD)
                // console.log(this.data)
                this.setState({

                    data: ttask,
                    get_acthours: [...this.state.get_acthours, ...res12.data]
                })

            })
            .catch(error => {
                this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                    variant: 'error'
                });

            });
    }

    // getActualScHours(ttask, pid) {
    //     var ids = "0"
    //     for (let i = 0; i < pid.length; i++) {
    //         const element = pid[i];
    //         ids += "," + element.ttid
    //     }
    //     Service.get('/bb/api/tender/roles/subcontract/?t_tasks=' + ids, {
    //         headers: {
    //             Authorization: "Token " + token,
    //         },
    //     })
    //         .then(res12 => {

    //             for (let j = 0; j < res12.data.length; j++) {
    //                 const element13 = res12.data[j];
    //                 for (let i = 0; i < ttask.length; i++) {
    //                     const element12 = ttask[i];
    //                     if (!element12.actual_cost) {
    //                         element12.actual_cost = 0
    //                     }
    //                     if (element12.id === element13.t_tasks) {

    //                         for (let i = 0; i < this.state.fullData.length; i++) {
    //                             const element14 = this.state.fullData[i];
    //                             if (element14.id === element13.t_role) {
    //                                 element12.actual_cost += this.costScCalc(element14, this.state.rates, element13.act_hours)
    //                                 break
    //                             }
    //                         }

    //                     }
    //                     // console.log(timeToDecimal(element13.hours_actual) !== 0)
    //                 }

    //             }

    //             this.setState({

    //                 data: ttask,

    //             })

    //         })
    //         .catch(error => {
    //             this.props.enqueueSnackbar('Hours utilized data unavailable.', {
    //                 variant: 'error'
    //             });

    //         });
    // }

    // getAssignedUsers(ttask, pid) {
    //     Service.patch("/bb/api/tender/wbs/tasks/get_assignusers/", {
    //         headers: {
    //             Authorization: "Token " + token,
    //         },
    //         data: pid,
    //     })
    //         .then(res21 => {
    //             // // console.log(res12.data)

    //             // for (let j = 0; j < ttask.length; j++) {
    //             //     const element13 = ttask[j];
    //             //     var names = ''
    //             //     for (let i = 0; i < res21.data.length; i++) {
    //             //         const element12 = res21.data[i];
    //             //         if (element12.id === element13.id) {
    //             //             if (element12.users.length) {
    //             //                 for (let k = 0; k < element12.users.length; k++) {
    //             //                     const element14 = element12.users[k];
    //             //                     if (names !== '') {
    //             //                         names = names + ", " + element14.first_name + "(" + element14.hours + ")"
    //             //                     } else {
    //             //                         names = element14.first_name + "(" + element14.hours + ")"
    //             //                     }
    //             //                 }
    //             //             }
    //             //             else {
    //             //                 names = 'NA'
    //             //             }
    //             //             element13.assign_users = names
    //             //         }
    //             //         // console.log(timeToDecimal(element13.hours_actual) !== 0)
    //             //     }

    //             // }

    //             // // console.log(res.data)

    //             // for (let j = 0; j < this.data.length; j++) {
    //             //     const element13 = this.data[j];
    //             //     let names = ''
    //             //     for (let i = 0; i < res21.data.length; i++) {
    //             //         const element12 = res21.data[i];
    //             //         if (element12.id === element13.id) {
    //             //             for (let k = 0; k < element12.users.length; k++) {
    //             //                 const element14 = element12.users[k];
    //             //                 // if (names !== '') {
    //             //                 //     names = names + ", " + element14.first_name + "(" + element14.hours + ")"
    //             //                 // } else {
    //             //                 names = element14.first_name + "(" + element14.hours + ")"
    //             //                 // }
    //             //             }
    //             //             element13.assign_users = names
    //             //         }
    //             //         // console.log(timeToDecimal(element13.hours_actual) !== 0)

    //             //     }

    //             // }

    //             // console.log(this.data)
    //             this.setState({
    //                 data: ttask,
    //                 get_assignusers: [...this.state.get_assignusers, ...res21.data]
    //             })
    //         })
    //         .catch(error => {
    //             this.props.enqueueSnackbar('Assigned users data unavailable.', {
    //                 variant: 'error'
    //             });

    //         });
    // }

    // getFirstCompletionChange(ids) {
    //     Service.get2("/bb/api/tender/wbs/tasks/get_first_comp_change/?tasks_id=" + ids, {
    //         headers: {
    //             Authorization: "Token " + token,
    //         },
    //     }).then(res => {
    //         // console.log(res.data)
    //         this.setState({
    //             firstCompletionChange: res.data
    //         })
    //     })
    //         .catch(error => {
    //             console.log(error)
    //             errorMessage(this.props, error)
    //         });
    // }

    fetchData() {

        var url = `/bb/api/tender/wbs/tasks/get_task_data/?ds=` + this.props.value.id

        if (this.props.allDataTabs.includes(this.props.tabSelected)) {
            url += "&filter_type=all"
        } else {
            url += "&filter_type=limited"
        }

        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    if (element.task_users.length > 0) {
                        element.assigned_string = element.task_users[0].user_id__first_name + " (" + element.task_users[0].planed_hours + ")"
                    }
                    for (let j = 1; j < element.task_users.length; j++) {
                        const assigned_users = element.task_users[j];
                        element.assigned_string += ", " + assigned_users.user_id__first_name + " (" + assigned_users.planed_hours + ")"
                    }
                }
                this.data = res.data

                this.filterData('0')
                // if (this.props.value.contract_type) {
                //     Service.get('/bb/api/tender/rate/?pid=' + this.props.value.id, {
                //         headers: {
                //             Authorization: "Token " + token,
                //         },

                //     })
                //         .then(res2 => {

                //             this.processData(res, refreshData, res2.data)


                //         })
                //         .catch(error => {
                //             errorMessage(this.props, error)
                //         });
                // } else {
                //     this.processData(res, refreshData, null)
                // }


            })
            .catch(error => {
                errorMessage(this.props, error)
            });

    }

    // processData(res, refreshData, rates) {
    //     var viewPid = []
    //     var ttask = [];
    //     var task_ids = []

    //     for (let i = 0; i < res.data.length; i++) {
    //         const element = res.data[i];

    //         if (element.hours !== 0 || element.req_hours !== 0) {
    //             var isTasks = false;
    //             for (let i = 0; i < ttask.length; i++) {
    //                 const element1 = ttask[i];
    //                 if (element.t_tasks_data.id === element1.id) {
    //                     isTasks = true;
    //                 }
    //             }

    //             if (!isTasks) {
    //                 var eleData = JSON.parse(JSON.stringify(element.t_tasks_data));
    //                 // eleData.tender_map_id = element.id;
    //                 // console.log(eleData

    //                 if (eleData.from_date) {
    //                     eleData.from_date_str = moment(eleData.from_date).format("DD/MM/YYYY")
    //                 }

    //                 if (eleData.to_date) {
    //                     eleData.to_date_str = moment(eleData.to_date).format("DD/MM/YYYY")
    //                 }

    //                 if (eleData.act_exp_start) {
    //                     eleData.act_exp_start_str = moment(eleData.act_exp_start).format("DD/MM/YYYY")
    //                 }

    //                 if (eleData.act_exp_finish) {
    //                     eleData.act_exp_finish_str = moment(eleData.act_exp_finish).format("DD/MM/YYYY")
    //                 }

    //                 var roleinfo = '';
    //                 var rolehour = 0;
    //                 var rolecost = 0;
    //                 for (let m = 0; m < res.data.length; m++) {
    //                     const element5 = res.data[m];
    //                     if (element5.t_tasks === eleData.id) {
    //                         if (roleinfo === '') {
    //                             roleinfo = element5.name;
    //                         } else {
    //                             roleinfo = roleinfo + ", " + element5.name;
    //                         }
    //                         rolehour = rolehour + element5.hours;
    //                         if (this.props.value.contract_type) {
    //                             rolecost += this.costCalc(element, rates)
    //                         }

    //                     }
    //                 }

    //                 // var mapinfo = '';
    //                 // for (let m = 0; m < mapdata.length; m++) {
    //                 //     const element6 = mapdata[m];
    //                 //     if (element6.task_id === eleData.id) {
    //                 //         if (roleinfo === '') {
    //                 //             mapinfo = element6.user_data.first_name.substring(0, 1) + element6.user_data.last_name + "(" + element6.planed_hours + ")";
    //                 //         } else {
    //                 //             mapinfo = mapinfo + ", " + element6.user_data.first_name.substring(0, 1) + element6.user_data.last_name + "(" + element6.planed_hours + ")";
    //                 //         }
    //                 //     }
    //                 // }

    //                 eleData.rolesConn = roleinfo;
    //                 eleData.taskHours = rolehour;
    //                 eleData.taskCost = rolecost;
    //                 // eleData.userComm = mapinfo;

    //                 viewPid.push({ 'ttid': eleData.id })
    //                 eleData.name = eleData.decs ? eleData.decs : eleData.name
    //                 ttask.push(eleData)
    //                 task_ids.push(eleData.id)
    //             }
    //         }

    //     }

    //     if (refreshData && task_ids.length > 0) {
    //         this.getFirstCompletionChange(task_ids)
    //     }
    //     this.data = ttask

    //     this.setState({
    //         isLoading: false,
    //         data: ttask,
    //         rates: rates,
    //         fullData: res.data,
    //     }, () => {
    //         if (refreshData && ttask.length !== 0) {
    //             if (this.state.get_acthours.length) {
    //                 this.setState({
    //                     get_assignusers: [],
    //                     get_acthours: []
    //                 }, () => {
    //                     this.filterData(this.state.filterStatus)
    //                 })
    //             } else {
    //                 this.filterData(this.state.filterStatus)
    //             }


    //             // this.getActualHours(ttask);
    //             // this.getAssignedUsers(ttask);

    //         } else if (!this.props.value.contract_type) {
    //             for (let j = 0; j < this.state.get_acthours.length; j++) {
    //                 const element13 = this.state.get_acthours[j];
    //                 for (let i = 0; i < ttask.length; i++) {
    //                     const element12 = ttask[i];
    //                     if (element12.id === element13.id) {
    //                         element12.actual_hours = timeToDecimal(element13.hours_actual)
    //                         break;
    //                     }
    //                     // console.log(timeToDecimal(element13.hours_actual) !== 0)
    //                 }

    //             }
    //             for (let j = 0; j < ttask.length; j++) {
    //                 const element13 = ttask[j];
    //                 var names = ''
    //                 for (let i = 0; i < this.state.get_assignusers.length; i++) {
    //                     const element12 = this.state.get_assignusers[i];
    //                     if (element12.id === element13.id) {
    //                         for (let k = 0; k < element12.users.length; k++) {
    //                             const element14 = element12.users[k];
    //                             if (names !== '') {
    //                                 names = names + ", " + element14.first_name + "(" + element14.hours + ")"
    //                             } else {
    //                                 names = element14.first_name + "(" + element14.hours + ")"
    //                             }
    //                         }
    //                     }
    //                     // console.log(timeToDecimal(element13.hours_actual) !== 0)
    //                 }
    //                 element13.assign_users = names

    //             }
    //             this.setState({

    //                 data: ttask,
    //                 // get_assignusers: res21.data
    //             }, () => {
    //                 this.data = ttask
    //                 this.filterData(this.state.filterStatus)
    //             })
    //         }

    //     });

    //     // console.log(ttask)
    // }

    costCalc(data1, rates) {
        if (data1.country === 'in' || data1.country === 'sl') {
            for (let i = 0; i < rates[0].t_rates.length; i++) {
                const element = rates[0].t_rates[i];
                if (data1.grade.toLowerCase() === element.grade.toLowerCase()) {
                    // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
                    return data1.hours * element.costin;
                }
            }
        } else {
            for (let i = 0; i < rates[0].t_rates.length; i++) {
                const element = rates[0].t_rates[i];
                if (data1.grade.toLowerCase() === element.grade.toLowerCase()) {
                    return data1.hours * element.costuk;
                }
            }
        }
        return 'null';
    }

    costScCalc(data1, rates, hours) {
        if (data1.country === 'in' || data1.country === 'sl') {
            for (let i = 0; i < rates[0].t_rates.length; i++) {
                const element = rates[0].t_rates[i];
                if (data1.grade.toLowerCase() === element.grade.toLowerCase()) {
                    // console.log(data1.no_off + "  " + data1.hours + " " + element.costin)
                    return hours * element.costin;
                }
            }
        } else {
            for (let i = 0; i < rates[0].t_rates.length; i++) {
                const element = rates[0].t_rates[i];
                if (data1.grade.toLowerCase() === element.grade.toLowerCase()) {
                    return hours * element.costuk;
                }
            }
        }
        return 'null';
    }

    putOpsPre(changes) {
        var fail = false
        var history = []
        var task = []
        var completeTask = []
        for (let chnage_index = 0; chnage_index < this.state.data.length; chnage_index++) {
            if (changes[chnage_index.toString()]) {
                var index = -1
                for (let i = 0; i < this.data.length; i++) {
                    const element = this.data[i];
                    if (element.id === changes[chnage_index.toString()].oldData.id) {
                        index = i

                        break;
                    }
                }
                var putData = this.putOps(changes[chnage_index.toString()].newData, changes[chnage_index.toString()].oldData, index)
                if (putData) {
                    if (putData.history) {
                        history.push(putData.history)
                        task.push(putData.task)
                    } else if (putData.task) {
                        task.push(putData.task)
                    } else {
                        fail = true
                    }
                    // console.log(putData)
                    if (putData.complete_task) {
                        completeTask.push(putData.task.id)
                    }
                } else {
                    fail = true
                }
            }

        }
        // console.log(completeTask)
        if (!fail) {
            if (completeTask.length) {
                this.setState({
                    openCompleteTask: true
                })
                postAPIDataBackup = {
                    history: history,
                    task: task,
                    completeTask: completeTask
                }
            } else {
                this.putOpsAPICalls(task, completeTask)
            }

        } else {
            this.fetchData()
        }

    }

    putOps(data, oldData, index) {
        data.from_date = moment(data.from_date).set({
            hour: moment().hour(),
            minute: moment().minutes(),
            second: moment().seconds()
        }).local()
        data.to_date = moment(data.to_date).set({
            hour: moment().hour(),
            minute: moment().minutes(),
            second: moment().seconds()
        }).local()
        data.act_exp_start = moment(data.act_exp_start).set({
            hour: moment().hour(),
            minute: moment().minutes(),
            second: moment().seconds()
        }).local()
        data.act_exp_finish = moment(data.act_exp_finish).set({
            hour: moment().hour(),
            minute: moment().minutes(),
            second: moment().seconds()
        }).local()
        // console.log(this.state.firstCompletionChange[data.id])
        // console.log(data.act_exp_start && data.act_exp_finish && data.act_exp_finish > data.act_exp_start)
        if (data.act_exp_start && data.act_exp_finish && moment(data.act_exp_finish) > moment(data.act_exp_start) &&
            (data.timesheet_start === null || moment(data.act_exp_start).format('YYYY-MM-DD') <= moment(data.timesheet_start).format('YYYY-MM-DD'))) {

            if (data.percentage_change_start !== null && moment(data.act_exp_start) > moment(data.percentage_change_start)) {
                this.props.enqueueSnackbar('Something is wrong with task "' + data.id + " - " + (data.decs ? data.dec : data.name) + '". Please contact Support Team through Help and Support page or mail us at support@bbparam.com', {
                    variant: 'warning',
                    autoHideDuration: 5000,
                });
            }

            var pre_comp_percent = Number(oldData.comp_percnt)
            var curr_comp_percent = Number(data.comp_percnt)
            if (pre_comp_percent !== data.comp_percnt) {
                this.data[index]["comp_percnt"] = curr_comp_percent;
                this.data[index]["from_date"] =
                    data["from_date"];
                this.data[index]["to_date"] =
                    data["to_date"];
                this.data[index]["act_exp_start"] =
                    data["act_exp_start"];
                this.data[index]["act_exp_finish"] =
                    data["act_exp_finish"];
                return {
                    history: {
                        "t_pid": data.t_pid_id,
                        "t_wbs": data.t_ohlwbs_id,
                        "t_tasks": data.id,
                        "prev_task_status": pre_comp_percent,
                        "task_status": curr_comp_percent,
                        "created_by": user.id,
                    },
                    task: data,
                    complete_task: (curr_comp_percent === Number(100)) ? true : false
                }
            } else {
                return {
                    task: data,
                    complete_task: false
                }
            }

        } else {
            this.data[index]["comp_percnt"] = Number(
                oldData["comp_percnt"]
            );
            this.data[index]["from_date"] =
                oldData["from_date"];
            this.data[index]["to_date"] =
                oldData["to_date"];
            this.data[index]["act_exp_start"] =
                oldData["act_exp_start"];
            this.data[index]["act_exp_finish"] =
                oldData["act_exp_finish"];
            // this.filterData(this.state.filterStatus)
            if (data.timesheet_start !== null && moment(data.act_exp_start).format("YYYY-MM-DD") > moment(data.timesheet_start).format("YYYY-MM-DD")) {
                this.props.enqueueSnackbar('Actual Start date should be before or equals to ' + moment(data.timesheet_start).format('DD/MM/YYYY') + ' for task "' + data.id + " - " + (data.decs ? data.dec : data.name) + '"', {
                    variant: 'warning'
                });
            } else {
                this.props.enqueueSnackbar('Actual Start and Finish is manadatory for task "' + data.id + " - " + (data.decs ? data.dec : data.name) + '"', {
                    variant: 'warning'
                });
            }

            return null
        }

    }

    putOpsAPICalls(task, completeTask) {
        Service.patch('/bb/api/tender/wbs/tasks/bulk_update_workbook/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: task,
        })
            .then(res1 => {
                if (completeTask.length) {
                    Service.patch('/bb/api/tender/wbs/tasks/complete_task/', {
                        headers: {
                            Authorization: "Token " + token,
                        },
                        data: {
                            "ttids": completeTask
                        }
                    })
                        .catch(error => {
                            console.log(error)
                            errorMessage(this.props, error)
                        });
                }

                this.props.enqueueSnackbar('Data Updated Successfully', {
                    variant: 'success'
                });
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    checkAccessDecipline(element) {
        if ((access.access.l4a.l4a_n && element.tenderbid_data.lead_dicipline === 'ohl') || (access.access.l4a.l4a_o && element.tenderbid_data.lead_dicipline === 'cabling')
            || (access.access.l4a.l4a_p && element.tenderbid_data.lead_dicipline === 'sub_struc') || (access.access.l4a.l4a_q && element.tenderbid_data.lead_dicipline === 'ohl_civ_struc')
            || (access.access.l4a.l4a_r && element.tenderbid_data.lead_dicipline === 'sub_civ_struct') || (access.access.l4a.l4a_s && element.tenderbid_data.lead_dicipline === 'multi_utilities')
            || (access.access.l4a.l4a_ag && element.tenderbid_data.lead_dicipline === 'technical')
            || (access.access.l4a.l4a_t && element.tenderbid_data.lead_dicipline === 'edc')) {
            return true;
        }
        return false
    }

    postHoldTask(data) {
        // console.log(data)
        let errorMessage1 = ""
        if (!data[0].hold_start) {
            if (errorMessage1) {
                errorMessage1 += ", Hold Start"
            } else {
                errorMessage1 = "Hold Start"
            }
        }
        if (!data[0].hold_end) {
            if (errorMessage1) {
                errorMessage1 += ", Hold End"
            } else {
                errorMessage1 = "Hold End"
            }
        }
        if (!data[0].comment.trim()) {
            if (errorMessage1) {
                errorMessage1 += ", Comment"
            } else {
                errorMessage1 = "Comment"
            }
        }
        if (errorMessage1) {
            this.setState({
                // openHold: false,
                isLoading: false
            })
            this.props.enqueueSnackbar(errorMessage1 + ' is  Empty!', {
                variant: 'warning'
            });
        } else {
            this.setState({
                openHold: false,
                isLoading: data.length > 0
            })
            Service.post('/bb/api/tender/tasks/hold/set_hold/', {
                headers: {
                    Authorization: "Token " + token,
                },
                data: data,
            })
                .then(res => {

                    this.setState({
                        isLoading: false
                    })

                    this.props.enqueueSnackbar('Tasks Holded!', {
                        variant: 'success'
                    });
                })
                .catch(error => {
                    this.setState({
                        isLoading: true
                    })
                    this.fetchData()
                    console.log(error)
                    errorMessage(this.props, error)
                    // this.fetchOps();
                });
        }

    }

    allHoldDataCheck() {
        let data = this.state.data
        let postdata = []

        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element.task_hold === 0) {
                // element.task_hold = 1
                postdata.push(element)
            }
        }
        return postdata
    }

    allTaskLineTypeCheck() {
        let data = this.state.data
        let postdata = []

        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element.inc_evp === 1) {
                // alert()
                // element.task_hold = 1
                postdata.push(element)
            }
        }
        return postdata
    }

    allTaskLineData1Format() {
        let data = this.state.data
        let postdata = []
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element.inc_evp === 1) {
                // element.task_hold = 1
                postdata.push(element)
            }
        }
        return postdata
    }

    allTaskLineData0Format() {
        let data = this.state.data
        let postdata = []
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element.inc_evp === 0) {
                // element.task_hold = 1
                postdata.push(element)
            }
        }
        return postdata
    }

    allHoldDataFormat() {
        let data = this.state.data
        let postdata = []
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element.task_hold === 0) {
                // element.task_hold = 1
                postdata.push(element)
            }
        }
        return postdata
    }

    allUnholdDataFormat() {
        let data = this.state.data
        let postdata = []
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (element.task_hold === 1) {
                // element.task_hold = 1
                postdata.push(element)
            }
        }
        return postdata
    }

    triggerRefresh() {
        triggerRefresh = true

    }

    putUnholdData(data, end_status) {
        // alert(end_status)
        var ids = '0'
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            ids += "," + element.id
        }

        Service.get('/bb/api/tender/tasks/hold/?status=0&task_id=' + ids, {
            headers: {
                Authorization: "Token " + token,
            },
            data: data
        })
            .then(res => {
                // console.log(res.data)
                var toDelete = "0"
                var putData = []
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    element.task_hold = 0
                    if (end_status === 1) {
                        // console.log(moment(element.hold_start))
                        if (moment(element.hold_start) > moment().subtract(1, 'days')) {
                            toDelete += "," + element.id
                            element.status = 1
                            element.task_hold = 0
                            element.hold_end = moment().subtract(1, 'days').format()
                            putData.push(element)
                        } else {
                            element.status = 1
                            element.task_hold = 0
                            element.hold_end = moment().subtract(1, 'days').format()
                            putData.push(element)
                        }

                    } else if (end_status === 2) {
                        element.status = 0
                        element.task_hold = 1
                        element.hold_end = moment().format()
                        putData.push(element)
                    } else if (end_status === 0) {
                        element.status = 0
                        element.task_hold = 1
                        element.hold_end = this.state.holdDate2
                        putData.push(element)
                    }
                }

                Service.patch('/bb/api/tender/tasks/hold/unhold/', {
                    headers: {
                        Authorization: "Token " + token,
                    },
                    data: putData
                })
                    .then(res => {
                        if (end_status === 1) {
                            // this.props.triggerRefresh()
                            this.fetchData()
                        } else if (end_status === 2) {
                            this.fetchData()
                        } else {
                            this.fetchData()
                        }
                        if (toDelete !== "0") {
                            Service.patch('/bb/api/tender/tasks/hold/unhold_delete/?delete=' + toDelete, {
                                headers: {
                                    Authorization: "Token " + token,
                                },

                            })
                                .then(res => {
                                })
                                .catch(error => {
                                    errorMessage(this.props, error)
                                    // this.fetchOps();
                                });
                        }

                    })
                    .catch(error => {

                        this.fetchData()
                        console.log(error)
                        errorMessage(this.props, error)
                        // this.fetchOps();
                    });


            })
            .catch(error => {

                errorMessage(this.props, error)
                // this.fetchOps();
            });

        this.setState({
            isLoading: true,
            openUnhold: false
        })

    }

    changeBillable(data) {
        this.setState({ openBillable: false })
        var ids = []
        for (let i = 0; i < data.length; i++) {
            const element = data[i];
            ids.push(element.id)
        }
        Service.patch("/bb/api/tender/wbs/tasks/change_billable/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: {
                ids: ids,
                status: data[0].inc_evp === 1 ? 0 : 1
            },
        })
            .then(res => {
                var data1 = this.state.data
                for (let i = 0; i < data.length; i++) {
                    const element = data[i];
                    for (let j = 0; j < data1.length; j++) {
                        const element1 = data1[j];
                        if (element.id === element1.id) {
                            element1.inc_evp = element1.inc_evp === 1 ? 0 : 1
                        }
                    }
                    // for (let j = 0; j < this.data.length; j++) {
                    //     const element1 = this.data[j];
                    //     if (element.id === element1.id) {
                    //         element1.inc_evp = element1.inc_evp === 1 ? 0 : 1
                    //     }
                    // }
                }
                this.setState({
                    data: data1
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    filterData(type) {
        this.setState({ filterStatus: type });
        let filtered = []
        let viewPid = []
        switch (type) {
            case '0':
                for (let i = 0; i < this.data.length; i++) {
                    if (this.data[i].comp_percnt === 0) {
                        filtered.push(this.data[i])
                        viewPid.push({ 'ttid': this.data[i].id })
                    }
                }
                break;
            // case 'I' :
            //     for(let i= 0;i<this.data.length;i++ ){
            //         if(this.data[i].comp_percnt === 0 && this.data[i].actual_hours > 0){
            //             filtered.push(this.data[i])
            //             viewPid.push({ 'ttid': this.data[i].id })
            //         }
            //     }
            //     break;
            case 'other':
                for (let i = 0; i < this.data.length; i++) {
                    if (this.data[i].comp_percnt > 0 && this.data[i].comp_percnt < 100) {
                        if (this.data[i].comp_percnt !== 100) {
                            filtered.push(this.data[i])
                            viewPid.push({ 'ttid': this.data[i].id })
                        }

                    }
                }
                break;
            case '5-65':
                for (let i = 0; i < this.data.length; i++) {
                    if (this.data[i].comp_percnt > 0 && this.data[i].comp_percnt <= 65) {
                        if (this.data[i].comp_percnt !== 100) {
                            filtered.push(this.data[i])
                            viewPid.push({ 'ttid': this.data[i].id })
                        }

                    }
                }
                break;
            case '70-90':
                for (let i = 0; i < this.data.length; i++) {
                    if (this.data[i].comp_percnt > 65 && this.data[i].comp_percnt < 100) {
                        if (this.data[i].comp_percnt !== 100) {
                            filtered.push(this.data[i])
                            viewPid.push({ 'ttid': this.data[i].id })
                        }
                    }
                }
                break;
            case '100':
                for (let i = 0; i < this.data.length; i++) {
                    if (this.data[i].comp_percnt === 100) {
                        filtered.push(this.data[i])
                        viewPid.push({ 'ttid': this.data[i].id })
                    }
                }

                break;
            default:
                filtered = this.data
                for (let i = 0; i < this.data.length; i++) {
                    viewPid.push({ 'ttid': this.data[i].id })
                }
        }
        this.setState({ data: filtered, isLoading: false })
        let searchAssignedUser = []
        let searchActualHours = []
        // for(let i = 0; i<this.data.length; i++){
        //     for(let j = 0; j<viewPid.length; j++){
        //         if(this.data[i].id === viewPid[j].ttid){
        //             if(!this.data[i].assign_users || this.data[i].assign_users === ""){
        //                 searchAssignedUser.push(viewPid[j])
        //             }
        //             if(!this.data[i].actual_hours ){
        //                if(this.data[i].actual_hours !== 0) searchActualHours.push(viewPid[j])
        //             }
        //         }
        //     }
        // }
        for (let i = 0; i < viewPid.length; i++) {
            let user_match = false;
            let hours_match = false;
            for (let j = 0; j < this.state.get_assignusers.length; j++) {
                if (viewPid[i].ttid === this.state.get_assignusers[j].id) {
                    user_match = true
                }
            }
            for (let k = 0; k < this.state.get_acthours.length; k++) {
                if (viewPid[i].ttid === this.state.get_acthours[k].id) {
                    hours_match = true
                }
            }
            if (!user_match) searchAssignedUser.push(viewPid[i])
            if (!hours_match) searchActualHours.push(viewPid[i])
        }
        // if (searchAssignedUser.length > 0 && !this.props.value.contract_type) this.getAssignedUsers(filtered, searchAssignedUser)
        // if (searchActualHours.length > 0 && !this.props.value.contract_type) this.getActualHours(filtered, searchActualHours);
        // if (this.props.value.contract_type) {
        //     this.getActualScHours(filtered, searchAssignedUser)
        // }
    }

    fetchPerTaskData2(rowData) {
        this.setState({
            openCoffee: true
        })
        Service.get('/bb/api/tender/wbs/tasks/' + rowData.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({
                    openTaskLevelAllocation: true,
                    allData: res.data,
                    openCoffee: false,
                }, () => {
                    if (this.props.value.contract_type) {
                        Service.get('/bb/api/tender/rate/?pid=' + rowData.t_pid_id, {
                            headers: {
                                Authorization: "Token " + token,
                            },
                        })
                            .then(res2 => {
                                this.setState({
                                    rates: res2.data
                                })
                            })
                            .catch(error => {
                                errorMessage(this.props, error)
                            });
                    }
                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }

    openSubTender(data) {
        this.setState({ subTenderData: data }, () => { this.setState({ openSubTender: true }) })
    }

    updateDate() {
        var delayDateItems = this.state.delayDateItems
        var itemMissing = false

        for (let i = 0; i < delayDateItems.length; i++) {
            const element = delayDateItems[i];
            if (!element.hasOwnProperty('noun') || !element.hasOwnProperty('adjective')) {
                itemMissing = true
            }
        }
        if (!itemMissing) {
            let delayDateItems = this.state.delayDateItems
            let data = this.state.data
            let array = []
            for (let i = 0; i < delayDateItems.length; i++) {
                const element = delayDateItems[i];
                for (let j = 0; j < data.length; j++) {
                    let json = {}
                    const element1 = data[j];
                    if (element1.id === element.id) {
                        element.adjective = parseInt(element.adjective)
                        element.noun = parseInt(element.noun)
                        json["newData"] = element
                        json["oldData"] = element1
                        array.push(json)
                    }
                }
            }
            var concatArray = array.concat(this.state.NonDelayItems)
            this.putOpsPre(concatArray)
            this.setState({ delayDateDialog: false, delayDateItems: [], NonDelayItems: [] })
        } else {
            this.props.enqueueSnackbar('Please select nouns and adjectives for all rows', {
                variant: 'warning'
            });
        }
    }

    render() {

        const handleSubTenderClose = () => {
            this.setState({ openSubTender: false, subTenderData: null })
        };

        const handleClose = () => {
            this.setState({ openPDM: false, });
        };

        const handleBillableClose = () => {
            this.setState({ openBillable: false, });
        };
        const handleHoldClose = () => {
            this.setState({ openHold: false, });
        };

        const handleCompleteTaskClose = () => {
            this.setState({ openCompleteTask: false, isLoading: true });
            this.fetchData();
        };

        const handleUnholdClose = () => {
            this.setState({ openUnhold: false, });
        };

        const handleHoldDate1Change = (date) => {

            this.setState({ holdDate1: date, holdDate2: date })
        };
        const handleHoldDate2Change = (date) => {
            this.setState({ holdDate2: date })
        };

        const handleHoldCommentChange = (event) => {
            this.setState({
                holdComment: event.target.value
            })
        };

        const handleHoldDetailClose = () => {
            if (triggerRefresh) {
                triggerRefresh = false
                let editData = this.state.holdData
                editData.task_hold = 0

                this.setState({ openHoldDetail: false, holdData: editData });
                // this.fetchData()
            } else {
                this.setState({ openHoldDetail: false, });
            }

        };

        const handleHoldImmediateClose = () => {
            this.setState({ openUnholdImmediate: false, });
        };

        const exportCsv = (columnList, initialData) => {
            const columns = columnList.filter(columnDef => {
                return !columnDef.hidden && columnDef.field && columnDef.export !== false && !columnDef.nonExport;
            });

            const data = initialData.map(rowData =>
                columns.map(columnDef => {
                    if (columnDef.customExport === 'in_key') {
                        var curr_data = rowData
                        var keys = columnDef.field.split(".")
                        for (let i = 0; i < keys.length; i++) {
                            const element = keys[i];
                            curr_data = curr_data[element]
                        }
                        return curr_data
                    } else if (columnDef.customExport === 'hold') {
                        return rowData.task_hold ? "Hold" : "Unhold"
                    } else if (columnDef.customExport === 'billable') {
                        return rowData.inc_evp ? "Billable" : "Not Billable"
                    }
                    return columnDef.render ? columnDef.render(rowData) : columnDef.lookup ? columnDef.lookup[rowData[columnDef.field]] : rowData[columnDef.field];
                })
            );

            const builder = new CsvBuilder(this.props.value.name + " " + moment().format('DD-MM-YYYY hh:mm') + '.csv');
            builder
                .setDelimeter(',')
                .setColumns(columns.map(columnDef => columnDef.title))
                .addRows(data)
                .exportFile();
        };

        const delayDateDialogClose = () => {
            this.setState({ delayDateDialog: false, });
            this.fetchData();
        };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>

                        {/* <Grid container justify="center" spacing={2} style={{margin:0}}>
          
                    <Button
                        size="small"
                        style={{
                        ...c_blueGrey.button,
                        ...(this.state.filterStatus === 'all' ? c_blueGrey.fllled : {}),
                        }}
                        onClick={()=>{this.filterData('all')}} >
                        {'All'}
                    </Button>
                    <Button
                        size="small"
                        style={{
                        ...c_yellow.button,
                        ...(this.state.filterStatus === 'some' ? c_yellow.fllled : {}),
                        }}
                        onClick={()=>{this.filterData('some')}} >
                        {'5 - 90%'}
                    </Button>
                    <Button
                        size="small"
                        style={{
                        ...c_orange.button,
                        ...(this.state.filterStatus === '0' ? c_orange.fllled : {}),
                        }}
                        onClick={()=>{this.filterData('0')}} >
                        {'0%'}
                    </Button>
                    <Button
                        size="small"
                        style={{
                        ...c_green.button,
                        ...(this.state.filterStatus === '100' ? c_green.fllled : {}),
                        }}
                        onClick={()=>{this.filterData('100')}} >
                        {'100%'}
                    </Button>
        
            </Grid> */}



                        <MaterialTable
                            title={null}

                            columns={[
                                // {
                                //     title: 'Deliverables Type', field: 't_wbs_data.pid_data.type', editable: 'never',
                                //     lookup: { 0: 'Budget', 1: 'Change', },
                                // },
                                // {
                                //     title: 'Deliverables Schedule', field: 't_wbs_data.pid_data.name', editable: 'never',
                                // },
                                {
                                    title: 'WBS', field: 'wbs_name', editable: 'never',

                                },
                                {
                                    title: 'ID', field: 'id', editable: 'never', defaultSort: 'asc'

                                },
                                {
                                    title: 'Tasks', field: 'name', editable: 'never',
                                    render: rowData => rowData.decs ? rowData.decs : rowData.name

                                },
                                {
                                    title: 'P6 ID', field: 'p6',
                                    editable: ((this.props.tender.tenderbid_data.pdm === user.id && access && access.access && access.access.l4a.l4a_b) || (access && access.access && access.access.l4a.l4a_e)) ? 'onUpdate' : 'never',

                                },
                                // {
                                //     title: 'Roles', field: 'rolesConn', editable: 'never',

                                // },
                                // {
                                //     title: 'Baseline Start', field: 'from_date', editable: () => access.access.l4a && access.access.l4a.l4a_ac, type: 'date', hidden: !(access.access.l4a && access.access.l4a.l4a_ac),
                                //     validate: (row) => ({
                                //         isValid: moment(row.from_date).toDate() <= moment(row.to_date).toDate(),
                                //         helperText: moment(row.from_date).toDate() > moment(row.to_date).toDate() ? "Baseline Start is lesser than Baseline Finish" : '',
                                //     }),
                                //     render: rowData => rowData.from_date ? moment(rowData.from_date)?.format("DD/MM/YYYY") : "NA"
                                // },
                                // {
                                //     title: 'Baseline Finish', field: 'to_date', editable: () => access.access.l4a && access.access.l4a.l4a_ac, type: 'date', hidden: !(access.access.l4a && access.access.l4a.l4a_ac),
                                //     validate: (row) => ({
                                //         isValid: moment(row.from_date).toDate() <= moment(row.to_date).toDate(),
                                //         helperText: moment(row.from_date).toDate() > moment(row.to_date).toDate() ? "Baseline Finish is greater than Baseline Start" : '',
                                //     }),
                                //     render: rowData => rowData.to_date ? moment(rowData.to_date)?.format("DD/MM/YYYY") : "NA"
                                // },
                                // {
                                //     title: 'Complete (%)', field: 'comp_percnt',
                                //     lookup: { 0: '0%', 5: '5%', 10: '10%', 20: '20%', 30: '30%', 40: '40%', 50: '50%', 60: '60%', 65: '65%', 70: '70%', 80: '80%', 90: '90%', 100: '100%' },
                                // },
                                // {
                                //     title: 'Actual/Expected Start', field: 'act_exp_start', type: 'date',
                                //     validate: (row) => ({
                                //         isValid: this.state.firstCompletionChange[row.id].timesheet_start !== null && moment(row.act_exp_start).toDate() <= moment(this.state.firstCompletionChange[row.id].timesheet_start).toDate(),
                                //         helperText: this.state.firstCompletionChange[row.id].timesheet_start !== null && moment(row.act_exp_start).toDate() >= moment(this.state.firstCompletionChange[row.id].timesheet_start).toDate() ? "Actual Start date should be before " + moment(this.state.firstCompletionChange[row.id].timesheet_start).format('DD/MM/YYYY') : '',
                                //     }),
                                //     render: rowData => (rowData.act_exp_start) ? (moment(rowData.act_exp_start)?.format("DD/MM/YYYY")) : ('NA')
                                // },
                                // {
                                //     title: 'Actual/Expected Finish', field: 'act_exp_finish', type: 'date',
                                //     validate: (row) => ({
                                //         isValid: moment(row.act_exp_finish).toDate() >= moment(row.act_exp_start).toDate(),
                                //         helperText: moment(row.act_exp_finish).toDate() < moment(row.act_exp_start).toDate() ? "Actual/Expected Finish is greater than Actual/Expected Start" : '',
                                //     }),
                                //     render: rowData => (rowData.act_exp_finish) ? (moment(rowData.act_exp_finish)?.format("DD/MM/YYYY")) : ('NA')
                                // },
                                {
                                    title: 'Baseline Start to Finish', field: 'from_date', editable: () => false, nonExport: true,
                                    hidden: (this.props.tender.tenderbid_data.pdm === user.id),
                                    render: rowData => rowData.from_date ? moment(rowData.from_date)?.format("DD/MM/YYYY") + " to " + moment(rowData.to_date)?.format("DD/MM/YYYY") : "NA",
                                },
                                {
                                    title: 'Baseline Start', field: 'from_date', editable: () => access.access.l4a && access.access.l4a.l4a_ac, type: 'date',
                                    hidden: !((access.access.l4a && access.access.l4a.l4a_ac) || (this.props.tender.tenderbid_data.pdm === user.id)),
                                    render: rowData => rowData.from_date ? moment(rowData.from_date)?.format("DD-MM-YYYY") : "NA",
                                    editComponent: (props) =>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                autoOk
                                                variant='inline'
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                style={{ width: 145 }}
                                                id="date-picker-inline"
                                                helperText={!moment(props.rowData.from_date).isValid() ? "Invalid date format" : moment(props.rowData.from_date).toDate() > moment(props.rowData.to_date).toDate() ? "Baseline Start is lesser than Baseline Finish" : ''}
                                                error={(!moment(props.rowData.from_date).isValid()) || (moment(props.rowData.from_date).toDate() > moment(props.rowData.to_date).toDate())}
                                                value={props.value ? props.value : null}
                                                onChange={(e) => {
                                                    props.onChange(e)
                                                    console.log(props)
                                                }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>,
                                },
                                {
                                    title: 'Baseline Finish', field: 'to_date', editable: () => access.access.l4a && access.access.l4a.l4a_ac, type: 'date',
                                    hidden: !((access.access.l4a && access.access.l4a.l4a_ac) || (this.props.tender.tenderbid_data.pdm === user.id)),
                                    render: rowData => rowData.to_date ? moment(rowData.to_date)?.format("DD-MM-YYYY") : "NA",
                                    editComponent: props =>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                autoOk
                                                variant='inline'
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                style={{ width: 145 }}
                                                id="date-picker-inline"
                                                value={props.value ? props.value : null}
                                                helperText={!moment(props.rowData.to_date).isValid() ? "Invalid date format" : moment(props.rowData.from_date).toDate() > moment(props.rowData.to_date).toDate() ? "Baseline Finish is greater than Baseline Start" : ''}
                                                error={(!moment(props.rowData.to_date).isValid()) || (moment(props.rowData.from_date).toDate() > moment(props.rowData.to_date).toDate())}
                                                onChange={(e) => {
                                                    props.onChange(e)
                                                }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>,
                                },
                                {
                                    title: 'Complete (%)', field: 'comp_percnt',
                                    lookup: { 0: '0%', 5: '5%', 10: '10%', 20: '20%', 30: '30%', 40: '40%', 50: '50%', 60: '60%', 65: '65%', 70: '70%', 80: '80%', 90: '90%', 100: '100%' },
                                },
                                {
                                    title: 'Actual/Expected Start', field: 'act_exp_start', type: 'date',
                                    render: rowData => (rowData.act_exp_start) ? (moment(rowData.act_exp_start)?.format("DD-MM-YYYY")) : ('NA'),
                                    editComponent: props =>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                autoOk
                                                variant='inline'
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                style={{ width: 145 }}
                                                id="date-picker-inline"
                                                helperText={!moment(props.rowData.act_exp_start).isValid() ? "Invalid date format" : props.rowData.timesheet_start !== null && moment(props.rowData.act_exp_start).format("YYYY-MM-DD") > moment(props.rowData.timesheet_start).format("YYYY-MM-DD") ? "Actual Start date should be before or equals to " + moment(props.rowData.timesheet_start).format('DD/MM/YYYY') : ''}
                                                error={(!moment(props.rowData.act_exp_start).isValid()) || (props.rowData.timesheet_start === null ? false : props.rowData.timesheet_start !== null && moment(props.rowData.act_exp_start).format("YYYY-MM-DD") > moment(props.rowData.timesheet_start).format("YYYY-MM-DD"))}
                                                value={props.value ? props.value : null}
                                                FormHelperTextProps={{ style: { color: 'red' } }}
                                                onChange={(e) => {
                                                    props.onChange(e)
                                                }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>,
                                },
                                {
                                    title: 'Actual/Expected Finish', field: 'act_exp_finish', type: 'date',
                                    render: rowData => (rowData.act_exp_finish) ? (moment(rowData.act_exp_finish)?.format("DD-MM-YYYY")) : ('NA'),
                                    editComponent: props =>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                                autoOk
                                                variant='inline'
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                style={{ width: 145 }}
                                                id="date-picker-inline"
                                                helperText={(!moment(props.rowData.act_exp_finish).isValid()) ? "Invalid date format" : moment(props.rowData.act_exp_finish).toDate() < moment(props.rowData.act_exp_start).toDate() ? "Actual/Expected Finish is greater than Actual/Expected Start" : ''}
                                                error={(!moment(props.rowData.act_exp_finish).isValid()) || (moment(props.rowData.act_exp_finish).toDate() < moment(props.rowData.act_exp_start).toDate())}
                                                value={props.value ? props.value : null}
                                                FormHelperTextProps={{ style: { color: 'red' } }}
                                                onChange={(e) => {
                                                    props.onChange(e)
                                                }}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>,
                                },
                                {
                                    title: 'Timesheet Start', field: 'timesheet_start', type: 'date', editable: 'never',
                                    // render: rowData => (this.state.firstCompletionChange && this.state.firstCompletionChange[rowData.id] && this.state.firstCompletionChange[rowData.id].timesheet_start) ? (moment(this.state.firstCompletionChange[rowData.id].timesheet_start)?.format("DD/MM/YYYY")) : ('NA')
                                },
                                {
                                    title: 'Budget Hours', hidden: this.props.value.contract_type, field: 'budget_hours', editable: 'never',
                                },
                                {
                                    title: 'Hours at Completion', field: 'hours_at_completion', editable: 'never',
                                    hidden: this.props.value.contract_type,
                                    // render: rowData => (rowData.actual_hours || rowData.actual_hours === 0) ? (rowData.actual_hours) : (<CircularProgress style={{ height: '15px', width: '15px' }} />)
                                },
                                {
                                    title: 'Hours to date', field: 'hours_to_date', editable: 'never',
                                    hidden: this.props.value.contract_type,
                                    // render: rowData => (rowData.actual_hours || rowData.actual_hours === 0) ? (rowData.actual_hours) : (<CircularProgress style={{ height: '15px', width: '15px' }} />)
                                },
                                {
                                    title: 'Assigned',
                                    hidden: this.props.value.contract_type,
                                    field: 'assigned_string', editable: 'never',
                                    headerStyle: { minWidth: 300 }, cellStyle: { minWidth: 300 },
                                },
                                {
                                    title: 'Budget Cost', field: 'taskCost', editable: 'never',
                                    render: rowData => new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(rowData.budget_cost)
                                },
                                {
                                    title: 'Cost to date', field: 'cost_to_date', editable: 'never',
                                    hidden: !this.props.value.contract_type,
                                    render: rowData => new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(rowData.cost_to_date)
                                },
                                {
                                    title: 'Hold/ Unhold',
                                    hidden: this.props.value.contract_type,
                                    field: 'task_hold', editable: 'never',
                                    customExport: 'hold',
                                    render: rowData => (!rowData.task_hold) ? (<Button
                                        disabled={!((this.props.tender.tenderbid_data.pdm === user.id && access && access.access && access.access.l4a.l4a_b) || (access && access.access && access.access.l4a.l4a_e))}
                                        size="small" color="primary" onClick={() => { this.setState({ openHold: true, holdData: [rowData], }); }} >Unhold</Button>) : (<Button
                                            disabled={!((this.props.tender.tenderbid_data.pdm === user.id && access && access.access && access.access.l4a.l4a_b) || (access && access.access && access.access.l4a.l4a_e))}
                                            size="small" color="primary" onClick={() => { this.setState({ holdData: rowData, openHoldDetail: true }) }} >Hold</Button>)
                                },

                                {
                                    title: 'Billable/ Non-Billable',
                                    // hidden: this.props.value.contract_type,
                                    field: 'task_hold', editable: 'never',
                                    customExport: 'billable',
                                    render: rowData => (!rowData.inc_evp) ? (<Button
                                        disabled={!((this.props.tender.tenderbid_data.pdm === user.id && access && access.access && access.access.l4a.l4a_b) || (access && access.access && access.access.l4a.l4a_e))}
                                        size="small" color="primary" onClick={() => { this.changeBillable([rowData]) }} >Non-Billable</Button>) : (<Button
                                            disabled={!((this.props.tender.tenderbid_data.pdm === user.id && access && access.access && access.access.l4a.l4a_b) || (access && access.access && access.access.l4a.l4a_e))}
                                            size="small" color="primary" onClick={() => { this.changeBillable([rowData]) }} >Billable</Button>)
                                },

                                {
                                    title: 'Task Status', field: 'comp_percnt', editable: 'never',
                                    lookup: { 0: 'S0', 5: 'S0', 10: 'S0', 20: 'S0', 30: 'S0', 40: 'S0', 50: 'S0', 60: 'S0', 65: 'S1', 70: 'S2', 80: 'S3', 90: 'S4', 100: 'A1' },
                                },
                                {
                                    title: 'Task Progress', field: 'comp_percnt', editable: 'never',
                                    lookup: {
                                        0: 'Not Started', 5: 'Preparation of documents/drawings', 10: 'Preparation of documents/drawings', 20: 'Preparation of documents/drawings',
                                        30: 'Preparation of documents/drawings', 40: 'Preparation of documents/drawings', 50: 'Preparation of documents/drawings',
                                        60: 'Checked within Department', 65: 'Approved within Department', 70: 'Site comments received, reviewed',
                                        80: 'Multi-disciplinary Reviewed & Commented', 90: 'Design verified.', 100: 'Design assured'
                                    },
                                },
                                // {
                                //     title: 'BIM Docs (Complete %)', field: 'dummy', editable: 'never',
                                // },
                                // {
                                //     title: 'BIM Docs (Task Status)', field: 'dummy', editable: 'never',
                                // },

                            ]}
                            data={this.state.data}

                            options={{
                                padding: "dense",
                                grouping: true,
                                doubleHorizontalScroll: true,
                                paging: false,
                                exportButton: { csv: true },
                                toolbarButtonAlignment: 'left',
                                headerStyle: {
                                    position: "sticky",
                                    top: 0
                                },
                                maxBodyHeight: (window.innerHeight - 151) + "px",
                                exportCsv: (columns, data) => {
                                    exportCsv(columns, data)
                                }
                            }}
                            actions={[
                                rowData => ({
                                    icon: () => <WorkIcon />,
                                    tooltip: 'Resource Allocation',
                                    disabled: !((this.props.tender.tenderbid_data.pdm === user.id && access && access.access && access.access.l4a.l4a_b) || (access && access.access && access.access.l4a.l4a_e) || this.checkAccessDecipline(this.props.tender) || rowData.wbs_manager_allocation_access),
                                    // onClick: (event, rowData) => { this.setState({ allData: rowData, openTaskLevelAllocation: true, }) },
                                    onClick: (event, rowData) => { this.fetchPerTaskData2(rowData) },
                                }),
                                rowData => ({
                                    icon: () => <WebIcon />,
                                    tooltip: 'Planner',
                                    // disabled: !((this.props.tender.tenderbid_data.pdm === user.id && access && access.access && access.access.l4a.l4a_b) || (access && access.access && access.access.l4a.l4a_e) || this.checkAccessDecipline(this.props.tender) || this.props.value.audit_status === 13),
                                    onClick: (event, rowData) => { this.openSubTender(rowData) },
                                }),
                                {
                                    icon: () =>
                                        <Button
                                            size="small"
                                            style={{
                                                ...c_blueGrey.button,
                                                ...(this.state.filterStatus === 'all' ? c_blueGrey.fllled : {}),
                                            }}

                                        >
                                            {'All'}
                                        </Button>,
                                    isFreeAction: true,
                                    onClick: () => {
                                        this.filterData('all')

                                    }
                                },
                                {
                                    icon: () =>
                                        <Button
                                            size="small"
                                            style={{
                                                ...c_orange.button,
                                                ...(this.state.filterStatus === '0' ? c_orange.fllled : {}),
                                            }}
                                        >
                                            {'0%'}
                                        </Button>,
                                    isFreeAction: true,
                                    onClick: () => {
                                        this.filterData('0')

                                    }
                                },
                                // {
                                //     icon : ()=>  
                                //     <Tooltip title="% to be updated" aria-label="% to be updated">
                                //     <Button
                                //     size="small"
                                //     style={{
                                //     ...c_red.button,
                                //     ...(this.state.filterStatus === 'I' ? c_orange.fllled : {}),
                                //     }}
                                //     >
                                //     {'I'}
                                // </Button>
                                // </Tooltip>,
                                //  isFreeAction: true,
                                //  onClick :()=>{this.filterData('I')}
                                // },
                                // {
                                //     icon: () =>
                                //         <Button
                                //             size="small"
                                //             style={{
                                //                 ...c_yellow.button,
                                //                 ...(this.state.filterStatus === '5-65' ? c_yellow.fllled : {}),
                                //             }}
                                //         >
                                //             {'5%-65%'}
                                //         </Button>,
                                //     isFreeAction: true,
                                //     onClick: () => {
                                //         this.filterData('5-65')

                                //     }
                                // },
                                // {
                                //     icon: () =>
                                //         <Button
                                //             size="small"
                                //             style={{
                                //                 ...c_yellow.button,
                                //                 ...(this.state.filterStatus === '70-90' ? c_yellow.fllled : {}),
                                //             }}
                                //         >
                                //             {'70%-90%'}
                                //         </Button>,
                                //     isFreeAction: true,
                                //     onClick: () => {
                                //         this.filterData('70-90')

                                //     }
                                // },
                                {
                                    icon: () =>
                                        <Button
                                            size="small"
                                            style={{
                                                ...c_green.button,
                                                ...(this.state.filterStatus === '100' ? c_green.fllled : {}),
                                            }}
                                        >
                                            {'100%'}
                                        </Button>,
                                    isFreeAction: true,
                                    onClick: () => {
                                        this.filterData('100')

                                    }
                                },

                                {
                                    icon: () =>
                                        <Button
                                            size="small"
                                            style={{
                                                ...c_yellow.button,
                                                ...(this.state.filterStatus === 'other' ? c_yellow.fllled : null),
                                            }}
                                        >
                                            {'Other'}
                                        </Button>,
                                    isFreeAction: true,
                                    onClick: () => {
                                        this.filterData('other')

                                    }
                                },

                                // {
                                //     icon : ()=>   <Button
                                //     size="small"
                                //     style={{
                                //     ...c_orange.button,
                                //     ...(this.state.filterStatus === '0' ? c_orange.fllled : {}),
                                //     }}
                                //     onClick={()=>{this.filterData('0')}}
                                //     >
                                //     {'0%'}
                                // </Button>,
                                //      isFreeAction: true,
                                //      tooltip :'Complete Tasks',
                                //     //  onClick:()=>{this.filterData('0')}
                                // },
                                // {
                                //     icon : ()=>   <Button
                                //     size="small"
                                //     style={{
                                //     ...c_yellow.button,
                                //     ...(this.state.filterStatus === 'some' ? c_yellow.fllled : {}),
                                //     }}
                                //     onClick={ ()=>{this.filterData('some')}}
                                //      >
                                //     {'5 - 90%'}
                                // </Button>,
                                //      isFreeAction: true,
                                //      tooltip :'Complete Tasks',
                                //     //  onClick: ()=>{this.filterData('some')}
                                // },
                                // {
                                //     icon : ()=>  <Button
                                //     size="small"
                                //     style={{
                                //     ...c_green.button,
                                //     ...(this.state.filterStatus === '100' ? c_green.fllled : {}),
                                //     }}
                                //     onClick={()=>{this.filterData('100')}}
                                //      >
                                //     {'100%'}
                                // </Button>,
                                //      isFreeAction: true,
                                //      tooltip :'Complete Tasks',
                                //     //  onClick : ()=>{this.filterData('100')}
                                // },
                                {
                                    icon: () => this.allHoldDataCheck().length === 0 ? <Button
                                        disabled={!((this.props.tender.tenderbid_data.pdm === user.id && access && access.access && access.access.l4a.l4a_b) || (access && access.access && access.access.l4a.l4a_e)) || this.props.value.contract_type}
                                        variant='outlined'
                                        color="primary" size="small" >Unhold All</Button> : <Button size="small"
                                            variant='outlined'
                                            disabled={!((this.props.tender.tenderbid_data.pdm === user.id && access && access.access && access.access.l4a.l4a_b) || (access && access.access && access.access.l4a.l4a_e)) || this.props.value.contract_type}
                                            color="primary" >Hold All</Button>,
                                    tooltip: this.allHoldDataCheck().length === 0 ? 'This will change all tasks to unhold' : 'This will change all tasks to hold',
                                    isFreeAction: true,
                                    hidden: this.props.value.audit_status === 13,
                                    disabled: (!((this.state.usersData && this.props.tender.tenderbid_data.pdm === user.id && access && access.access && access.access.l4a.l4a_b) || (access && access.access && access.access.l4a.l4a_e)) || this.props.value.contract_type),
                                    onClick: (event, rowData) => {
                                        if (this.allHoldDataCheck().length === 0) {
                                            this.setState({ openUnhold: this.allHoldDataCheck().length === 0, holdData: this.allUnholdDataFormat(), })

                                        } else {
                                            this.setState({ openHold: this.allHoldDataCheck().length > 0, holdData: this.allHoldDataFormat(), })

                                        }
                                    },
                                },
                                {
                                    icon: () => this.allTaskLineTypeCheck().length > 0 ? <Button
                                        variant='outlined'
                                        disabled={!((this.props.tender.tenderbid_data.pdm === user.id && access && access.access && access.access.l4a.l4a_b) || (access && access.access && access.access.l4a.l4a_e))}
                                        color="primary" size="small" >Non-Billable All</Button> : <Button size="small"
                                            variant='outlined'
                                            disabled={!((this.props.tender.tenderbid_data.pdm === user.id && access && access.access && access.access.l4a.l4a_b) || (access && access.access && access.access.l4a.l4a_e))}
                                            color="primary" >Billable All</Button>,
                                    tooltip: this.allTaskLineTypeCheck() ? 'This will change all tasks to Billable' : 'This will change all tasks to Non-Billable',
                                    isFreeAction: true,
                                    hidden: this.props.value.audit_status === 13,
                                    disabled: (!((this.state.usersData && this.props.tender.tenderbid_data.pdm === user.id && access && access.access && access.access.l4a.l4a_b) || (access && access.access && access.access.l4a.l4a_e))),
                                    onClick: (event, rowData) => {
                                        if (this.allTaskLineTypeCheck().length > 0) {
                                            // console.log(this.allTaskLineData1Format())
                                            this.setState({ openBillable: this.allTaskLineData1Format().length > 0, billData: this.allTaskLineData1Format(), })
                                        } else {
                                            // console.log(this.allTaskLineTypeCheck())
                                            this.setState({ openBillable: this.allTaskLineData0Format().length > 0, billData: this.allTaskLineData0Format(), })
                                        }
                                    },
                                },
                                {
                                    icon: 'refresh',
                                    tooltip: "Refresh",
                                    isFreeAction: true,
                                    onClick: () => { this.setState({ isLoading: true }); this.fetchData(); },
                                    // onClick={() => { }}
                                },
                            ]}
                            editable={(((access && access.access && access.access.l4a.l4a_b) || (access && access.access && access.access.l4a.l4a_e)) && this.props.value.audit_status !== 13) ? (
                                {
                                    onBulkUpdate: changes =>
                                        new Promise((resolve, reject) => {
                                            // this.putOpsPre(changes)
                                            setTimeout(() => {
                                                // resolve();
                                                this.setState(prevState => {
                                                    let delayItems = []
                                                    let NonDelayItems = []
                                                    const data = [...prevState.data];
                                                    for (let i = 0; i < data.length; i++) {
                                                        const element = changes[i.toString()];
                                                        if (changes[i.toString()]) {
                                                            data[data.indexOf(element.oldData)] = element.newData;
                                                            let date = !element.oldData.act_exp_finish ? element.oldData.to_date : element.oldData.act_exp_finish
                                                            let date1 = !element.oldData.act_exp_finish ? moment(element.oldData.to_date) : moment(element.oldData.act_exp_finish)
                                                            let date2 = moment(element.newData.act_exp_finish)
                                                            if (moment(date2).isAfter(date1)) {
                                                                element.newData.old_act_exp_finish = date
                                                                delayItems.push(element.newData)
                                                            } else {
                                                                NonDelayItems.push(element)
                                                            }
                                                        }
                                                    }
                                                    if (delayItems.length > 0) {
                                                        this.setState({ NonDelayItems, delayDateItems: JSON.parse(JSON.stringify(delayItems)), delayDateDialog: true })
                                                        resolve();
                                                    }
                                                    if (NonDelayItems.length > 0 && delayItems.length === 0) {
                                                        this.setState({ NonDelayItems })
                                                        this.putOpsPre(NonDelayItems)
                                                        resolve();
                                                    }
                                                    return { ...prevState, data };
                                                });
                                            }, 1000);
                                        }),
                                }
                            ) : (null)}
                        />

                        <Dialog
                            fullScreen
                            open={this.state.openTaskLevelAllocation}
                            onClose={() => this.setState({ openTaskLevelAllocation: false })}
                            TransitionComponent={Transition}>
                            <AppBar sx={{ position: 'relative' }} color='primary'>
                                <Toolbar>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={() => this.setState({ openTaskLevelAllocation: false })}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" >
                                        {(this.state.allData) ? this.state.allData.id + " " + this.state.allData.name : null}
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <DialogContent >
                                <div style={{ marginTop: '60px' }}>
                                    {(this.props.value.contract_type) ? (
                                        <SubContractLevelTabs value={this.state.allData} ds={this.props.value} rates={this.state.rates} />
                                    ) : (
                                        // <TaskLevelTabs value={this.state.allData} usersData={this.state.usersData} />
                                        <Allocation value={this.state.allData} usersData={this.state.usersData} />
                                    )}
                                </div>
                            </DialogContent>
                        </Dialog>

                        {/* <Dialog
                            open={this.state.openPDM}
                            fullWidth={true}
                            maxWidth={'xl'}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogContent>
                                <SecuredProjectRoles value={this.state.allData} task={this.state.allData} />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose} color="primary">
                                    Close
                    </Button>

                            </DialogActions>
                        </Dialog> */}

                        <Dialog
                            open={this.state.openHold}
                            // fullWidth={true}
                            maxWidth={'xl'}
                            onClose={handleHoldClose}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogContent>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Box display="flex" flexDirection="row" p={1} m={1}>
                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="holdDate1"
                                            label="Hold Start Date"
                                            // disabled
                                            minDate={moment().subtract(30, 'days')}
                                            value={this.state.holdDate1}
                                            onChange={handleHoldDate1Change}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />

                                    </Box>
                                    {(this.state.holdDate1 < moment().subtract(20, "minutes")) ? (
                                        <h5 style={{ color: 'red' }}>The date selected is prior to today's date.<br />Are you sure?. You can not edit this date later.</h5>

                                    ) : (null)}

                                    <Box display="flex" flexDirection="row" p={1} m={1}>

                                        <KeyboardDatePicker
                                            disableToolbar
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="holdDate2"
                                            label="Hold End Date"
                                            minDate={this.state.holdDate1}
                                            value={this.state.holdDate2}
                                            onChange={handleHoldDate2Change}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change date',
                                            }}
                                        />

                                    </Box>
                                    <Box display="flex" flexDirection="row" p={1} m={1}>

                                        <TextField
                                            id="outlined-multiline-static"
                                            label="Comment"
                                            multiline
                                            rows={4}
                                            onChange={handleHoldCommentChange}
                                            defaultValue=""
                                            variant="outlined"
                                        />
                                    </Box>
                                </MuiPickersUtilsProvider>

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleHoldClose} color="primary">
                                    Close
                                </Button>

                                <Button onClick={() => {
                                    let data = []
                                    let holdData = this.state.holdData
                                    for (let i = 0; i < holdData.length; i++) {
                                        const element = holdData[i];
                                        element.task_hold = 1

                                        let postData = {}
                                        postData.tender = this.props.value.subproject_pid
                                        postData.t_pid = element.t_pid_id
                                        postData.t_wbs = element.t_ohlwbs_id
                                        postData.task_id = element.id
                                        postData.hold_start = this.state.holdDate1
                                        postData.hold_end = this.state.holdDate2
                                        postData.comment = this.state.holdComment
                                        postData.created_by = user.id
                                        data.push(postData)
                                    }

                                    this.setState({
                                        holdData: holdData,

                                    })
                                    // if (holdData.length === 1) {
                                    //     holdData[0].task_hold =  1

                                    // }

                                    this.postHoldTask(data)
                                }} color="primary">
                                    Submit
                                </Button>

                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={this.state.openHoldDetail}
                            fullWidth={true}
                            maxWidth={'xl'}
                            onClose={handleClose}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogContent>
                                <TaskHoldDetail value={this.state.holdData} triggerRefresh={this.triggerRefresh} />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleHoldDetailClose} color="primary">
                                    Close
                                </Button>

                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={this.state.openBillable}
                            fullWidth={true}
                            maxWidth={'xl'}
                            onClose={handleBillableClose}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogContent>
                                This process will change all the task to {(this.state.billData && this.state.billData.length > 0 && this.state.billData[0].inc_evp === 0) ? "Billable" : "Non-Billable"} tasks
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleBillableClose} color="primary">
                                    Close
                                </Button>
                                <Button onClick={() => this.changeBillable(this.state.billData)} color="primary">
                                    Continue
                                </Button>

                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={this.state.openUnhold}
                            // fullWidth={true}
                            maxWidth={'xl'}
                            onClose={handleUnholdClose}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogContent>

                                <Box display="flex" flexDirection="row" p={1} m={1}>
                                    <Button color="primary" onClick={() => {
                                        this.setState({ openUnholdImmediate: true })
                                    }}>Unhold Immediately</Button>
                                </Box>
                                <Box display="flex" flexDirection="row" p={1} m={1}>
                                    <Button color="primary" onClick={() => {
                                        this.putUnholdData(this.state.holdData, 2)
                                    }}>Unhold Tomorrow</Button>
                                </Box>

                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleUnholdClose} color="primary">
                                    Close
                                </Button>

                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={this.state.openUnholdImmediate}
                            // fullWidth={true}
                            maxWidth={'xl'}
                            onClose={handleHoldImmediateClose}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Are you sure? You want to unhold immediately?"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Choosing Immediate unhold will change the hold end date to yeasterday's date and the tasks will be unholded immediately
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleHoldImmediateClose} color="primary">
                                    Close
                                </Button>
                                <Button onClick={() => { this.setState({ openUnholdImmediate: false }); this.putUnholdData(this.state.holdData, 1) }} color="primary">
                                    Proceed
                                </Button>

                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={this.state.openCompleteTask}
                            // fullWidth={true}
                            maxWidth={'md'}
                            onClose={handleCompleteTaskClose}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"You have updated task(s) to 100% complete.."}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    This process will mark:<br />
                                    &nbsp;&nbsp;&nbsp;1. The task to completed status<br />
                                    &nbsp;&nbsp;&nbsp;2. All the allocated remaining hours to the individuals in this task will be zeroed out<br />
                                    &nbsp;&nbsp;&nbsp;3. All the CTC hours of the Roles will be set to zero<br />
                                    &nbsp;&nbsp;&nbsp;4. All the actual hours will be equalled to allocated hours<br />
                                    <pre />Note : If the user has done his timesheet but pending submission, they can still send it to you for approval. If the user has not initiated the timesheet for the hours allocated to them – those hours will be set to zero and will not be able to see the task in their calendar.

                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCompleteTaskClose} color="primary">
                                    Close
                                </Button>
                                <Button onClick={() => { this.setState({ openCompleteTask: false }); this.putOpsAPICalls(postAPIDataBackup.task, postAPIDataBackup.completeTask) }} color="primary">
                                    Proceed
                                </Button>

                            </DialogActions>
                        </Dialog>

                        <Dialog fullScreen open={this.state.openSubTender} aria-labelledby="customized-dialog-title" >
                            <AppBar position="static">
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={handleSubTenderClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" >
                                        {this.state.subTenderData ? this.state.subTenderData.name : null}
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            {/* <DialogContent> */}
                            <MainTenderSubTasks task_id={this.state.subTenderData ? this.state.subTenderData.id : null} />
                            {/* </DialogContent> */}
                        </Dialog>

                        <Dialog maxWidth="sm" open={this.state.openCoffee} aria-labelledby="form-dialog-title">
                            {/* <DialogTitle id="customized-dialog-title" onClose={handleFeedbackClose}>
                                    Additional Hours Request
                                </DialogTitle> */}
                            <DialogContent>
                                <Box m={5} p={2}>
                                    <CoffeeLoading />
                                </Box>
                            </DialogContent>
                        </Dialog>

                        <Dialog
                            open={this.state.delayDateDialog}
                            fullWidth={true}
                            maxWidth={'xl'}
                            // onClose={handleCompleteTaskClose}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle id="alert-dialog-title">
                                <Box display={'flex'} flexDirection={'row'} alignItems={'center'}>
                                    <Typography variant='h6'>Select reason for delay</Typography>
                                    <Box marginLeft={'10px'}>
                                        <PopupState variant="popover" popupId="demo-popup-popover">
                                            {(popupState) => (
                                                <div>
                                                    <Tooltip title='Click here for help'>
                                                        <IconButton size='medium'  {...bindTrigger(popupState)}>
                                                            <InfoIcon fontSize='medium' />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Popover
                                                        {...bindPopover(popupState)}
                                                        anchorOrigin={{
                                                            vertical: 'center',
                                                            horizontal: 'right',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                    >
                                                        <Box m={1} >
                                                            <img src={'https://assets.bbparam.com/images/reason_for_delay_def.png'} width='100%' height='100%' alt='' />
                                                        </Box>
                                                    </Popover>
                                                </div>
                                            )}
                                        </PopupState>
                                    </Box>
                                </Box>
                            </DialogTitle>
                            <DialogContent>
                                <MaterialTable
                                    title={<Alert severity='info' style={{ marginTop: '5px' }} >
                                        <b>Note: </b>You have to select nouns & adjectives for all the rows in below table by clicking on the <b>EDIT </b>button
                                    </Alert>}

                                    columns={[
                                        {
                                            title: 'WBS', field: 'wbs_name', editable: 'never',
                                        },
                                        {
                                            title: 'ID', field: 'id', editable: 'never', defaultSort: 'asc'
                                        },
                                        {
                                            title: 'Tasks', field: 'name', editable: 'never',
                                            render: rowData => rowData.decs ? rowData.decs : rowData.name

                                        },
                                        {
                                            title: 'Actual/Expected Start', field: 'act_exp_start', type: 'date', editable: 'never',
                                            render: rowData => (rowData.act_exp_start) ? (moment(rowData.act_exp_start)?.format("DD/MM/YYYY")) : ('NA'),
                                        },
                                        {
                                            title: 'Actual/Expected Finish', field: 'old_act_exp_finish', type: 'date', editable: 'never',
                                            render: rowData => (rowData.old_act_exp_finish) ? (moment(rowData.old_act_exp_finish)?.format("DD/MM/YYYY")) : ('NA'),
                                        },
                                        {
                                            title: 'Changed Actual/Expected Finish', field: 'act_exp_finish', type: 'date', editable: 'never',
                                            render: rowData => (rowData.act_exp_finish) ? (moment(rowData.act_exp_finish)?.format("DD/MM/YYYY")) : ('NA'),
                                        },
                                        {
                                            title: 'Reason for Delay/Noun', field: 'noun',
                                            lookup: this.state.nounsLookup
                                        },
                                        {
                                            title: 'Reason for Delay/Adjective', field: 'adjective',
                                            lookup: this.state.adjectiveLookup
                                        },
                                    ]}
                                    data={this.state.delayDateItems}
                                    options={{
                                        padding: "dense",
                                        paging: false,
                                        actionsColumnIndex: -1,
                                    }}
                                    editable={(((access && access.access && access.access.l4a.l4a_b) || (access && access.access && access.access.l4a.l4a_e)) && this.props.value.audit_status !== 13) ? (
                                        {
                                            onBulkUpdate: changes =>
                                                new Promise((resolve, reject) => {
                                                    setTimeout(() => {
                                                        resolve();
                                                        this.setState(prevState => {
                                                            const data = [...prevState.delayDateItems];
                                                            for (let i = 0; i < data.length; i++) {
                                                                if (changes[i.toString()]) {
                                                                    const element = changes[i.toString()];
                                                                    data[data.indexOf(element.oldData)] = element.newData;
                                                                }

                                                            }
                                                            return { ...prevState, delayDateItems: data };
                                                        });

                                                    }, 1000);
                                                }),
                                        }
                                    ) : (null)}

                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={delayDateDialogClose} color="primary">
                                    Discard Changes
                                </Button>
                                <Button onClick={() => { this.updateDate() }} color="primary">
                                    Proceed
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                )}
            </div>

        )
    }
}
function addZeroes(num) {
    const dec = num.toString().split('.')[1]
    const len = dec ? 1 : null
    if (len) {
        return parseFloat(Number(num).toFixed(len))
    } else {
        return parseFloat(Number(num))
    }
}

function timeToDecimal(t) {
    var arr = t.split(':');
    var dec = parseInt((arr[1] / 6) * 10, 10);

    return addZeroes(parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec));
}

export default withSnackbar(AllTenderData);