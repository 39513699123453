import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Service from "../Networking/networkutils";
import { withSnackbar } from 'notistack';
import { LoopCircleLoading } from 'react-loadingg';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import moment from 'moment';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Box } from '@material-ui/core';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { errorMessage } from '../Helper/ErrorMessage';

// import Moment from 'react-moment';
import { getCookie } from '../Helper/CookieHelper';
import ReactGA from 'react-ga';
import UserTimesheetReport from '../Reports/UserTimesheetReport/UserTimesheetReport';
import { getPrimaryColor } from '../Helper/CommonThings';
var momentRange = require('moment-range');
momentRange.extendMoment(moment);

const user = JSON.parse(getCookie("bb_ts_user"));
// const access = JSON.parse(getCookie("bb_ts_access"));
const token = getCookie("bb_ts_token");

class ManageTimeSheet extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            open: false,
            isLoading: true,
            isLoading1: true,
            selectDate: null,
            checkedB: true,
            tabledata: [],
            openTimesheetReport: false,
        }
    }


    componentDidMount() {
        var pageView = window.location.pathname;
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);
        this.fetchOps(`/bb/api/task/approve/limited/approval_data/?user_id=${user.id}&duration=13`);
    }

    fetchOps(url) {
        this.setState({ isLoading1: true, })
        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                this.setState({
                    data: res.data,
                    tabledata: res.data,
                    isLoading: false,
                    isLoading1: false,
                });
            })
            .catch(error => {
                errorMessage(this.props, error)
            });
    }


    // handleData(date) {
    //     var dataTo = [];
    //     for (let i = 0; i < this.state.data.length; i++) {
    //         const element = this.state.data[i];
    //         if (moment().range(moment(element.from_date), moment(element.to_date)).contains(date)) {
    //             dataTo.push(element);
    //         }
    //     }
    //     this.setState({ tabledata: dataTo })
    // }

    render() {

        const handleDateChange = (date) => {
            // console.log(this.state.selectTime1)
            this.setState({ selectDate: date, checkedB: false, });
            this.fetchOps(`/bb/api/task/approve/limited/approval_data/?user_id=${user.id}&date=${moment(date).format('YYYY-MM-DD')}`)
            // this.handleData(date);

        };

        const handleDialogClose = () => {
            this.setState({
                openTimesheetReport: false
            })
        };

        const handleChange = (event) => {
            this.setState({ checkedB: event.target.checked, selectDate: null });
            if (event.target.checked) {
                this.setState({ tabledata: this.state.data })
                this.fetchOps(`/bb/api/task/approve/limited/approval_data/?user_id=${user.id}&duration=13`);
            } else {
                // this.handleData(this.state.selectDate);
                // this.handleData(new Date());
                this.fetchOps(`/bb/api/task/approve/limited/approval_data/?user_id=${user.id}&date=${moment().format('YYYY-MM-DD')}`)
            }
        };

        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (
                    <div>
                        <Box display="flex" justifyContent="center" m={1} p={1}>
                            <FormGroup row style={{ marginRight: '15px' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.checkedB}
                                            onChange={handleChange}
                                            name="checkedB"
                                            color="primary"
                                        />
                                    }
                                    label="Show All Request"
                                />
                            </FormGroup>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="Select date of a Week"
                                    format="dd/MM/yyyy"
                                    style={{ marginLeft: '10px' }}
                                    value={this.state.selectDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            <Button style={{ marginLeft: '15px' }} variant='outlined' color="primary" onClick={() => this.setState({ openTimesheetReport: true })} >Timesheet Report</Button>
                        </Box>
                        <MaterialTable
                            title="My TimeSheets"
                            columns={[
                                { title: 'Team', field: 'gname', headerStyle: { minWidth: 275 }, cellStyle: { minWidth: 275 }, },
                                // { title: 'Comment', field: 'comment', headerStyle: { minWidth: 300 }, cellStyle: { minWidth: 300 }, },
                                {
                                    title: 'Request Type', field: 'request_type',
                                    lookup: { 0: 'Initial Data', 1: 'Edited Data' },
                                },
                                {
                                    title: 'Task From', field: 'from_date',
                                    render: rowData => <Typography>{moment(rowData.from_date).format('DD/MM/YYYY')}</Typography>
                                },
                                {
                                    title: 'Task To', field: 'to_date',
                                    render: rowData => <Typography>{moment(rowData.to_date).format('DD/MM/YYYY')}</Typography>
                                },
                                {
                                    title: 'Status', field: 'approve_status',
                                    lookup: { 0: 'Pending', 1: 'Approved', 2: 'Rejected', 3: 'Edit Requested', 4: 'Pending, Partially Approved', 5: 'Pending, Partially Rejected', 6: 'Approved, Rejected' },
                                },
                                {
                                    title: 'Replied By', field: 'replied_by',
                                    // render: rowData => 
                                },
                            ]}
                            data={this.state.tabledata}
                            isLoading={this.state.isLoading1}
                            actions={[
                                rowData => ({
                                    icon: 'launch',
                                    tooltip: 'Detail View',
                                    onClick: (event, rowData) => { window.open("/approvalv/" + rowData.id) },
                                }),
                            ]}
                            options={{
                                padding: "dense",
                                paging: false,
                                filtering: true,
                                actionsColumnIndex: -1
                            }}
                        />
                        <Dialog
                            open={this.state.openTimesheetReport}
                            onClose={handleDialogClose}
                            maxWidth={"lg"}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Timesheet Report"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Report will shared via E-mail
                                </DialogContentText>
                                <UserTimesheetReport teamUsers={false} userEnabled={false} withoutRateCost={true} />
                            </DialogContent>
                            <DialogActions>

                                <Button onClick={handleDialogClose} color="primary" autoFocus>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                )}
            </div>

        )
    }
}

export default withSnackbar(ManageTimeSheet);