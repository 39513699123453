import React, { Component } from 'react'
import MaterialTable from 'material-table'
import Service from "../Networking/networkutils";
import { withSnackbar } from 'notistack';
// import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Slide from '@material-ui/core/Slide';
import { LoopCircleLoading } from 'react-loadingg';
import { Badge, Box, Button, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, withStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { blue } from '@material-ui/core/colors';
import moment from "moment";
import { getCookie } from '../Helper/CookieHelper';
import AllTenderData from './AllTenderData';
import { errorMessage } from '../Helper/ErrorMessage';
import SpecialRateTab from './IndividualRate/SpecialRateTab';
import { getDarkMode, getPrimaryColor } from '../Helper/CommonThings';
import UserTimesheetReport from '../Reports/UserTimesheetReport/UserTimesheetReport';
import DateChangeReqManage from '../DateChangeRequest/DateChangeReqManage';
import DateRangeIcon from '@mui/icons-material/DateRange';
import WebIcon from '@material-ui/icons/Web';
import MainTenderSubTasks from '../TenderSubTasks/MainTenderSubTasks';

const useStyles = (theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));
const access = JSON.parse(getCookie("bb_ts_access"));
// var dialogmsg = "";
// var submitdata = null;
class SecuredDeliSch extends Component {

    constructor(props) {
        super(props)

        this.state = {
            data: [],
            rates: this.props.rates,
            data2: this.props.roles,
            open: false,
            isLoading: true,
            allData: 'null',
            openEdit: false,
            openCalc: false,
            key: 0,
            subproj: [],
            openIndiviRate: false,
            individualRateData: {},
            enableEdit: false,
            openTimesheetReport: false,
            selectedDS: {},
            dateRquestDialog: false,
            openSubTender: false,
            subTenderData: null
        }
        this.refreshData = this.refreshData.bind(this)
    }


    componentDidMount() {
        this.fetchOps()
    }

    fetchOps() {
        var dataD = []
        var viewPid = [];
        var url = null
        if (this.props.audit_status === 10) {
            url = "/bb/api/tender/project/available_workbook_ds/?pid=" + this.props.value.id
        } else if (this.props.audit_status === 11) {
            url = "/bb/api/tender/project/estimates_effort_ds/?pid=" + this.props.value.id
        } else if (this.props.audit_status === 13) {
            url = "/bb/api/tender/project/design_complete_ds/?pid=" + this.props.value.id
        }
        if (this.props.allDataTabs.includes(this.props.tabSelected)) {
            url += "&filter_type=all"
        } else {
            url += "&filter_type=limited"
        }

        Service.get(url, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                dataD = res.data
                for (let i = 0; i < dataD.length; i++) {
                    const element = dataD[i];
                    viewPid.push({ 'tpid': element.id })
                }

                this.setState({
                    data: dataD,
                    isLoading: false
                }, () => {
                    // if (viewPid.length !== 0) {
                    //     var url = "/bb/api/team/tasks/get_acthours/"
                    //     if (this.props.allDataTabs.includes(this.props.tabSelected)) {
                    //         url += "?filter_type=all"
                    //     }
                    //     Service.patch(url, {
                    //         headers: {
                    //             Authorization: "Token " + token,
                    //         },
                    //         data: viewPid,
                    //     })
                    //         .then(res12 => {

                    //             for (let j = 0; j < res12.data.length; j++) {
                    //                 const element13 = res12.data[j];
                    //                 for (let i = 0; i < dataD.length; i++) {
                    //                     const element12 = dataD[i];
                    //                     if (element12.id === element13.id) {
                    //                         element12.actual_hours = timeToDecimal(element13.hours_actual)
                    //                         break;
                    //                     }
                    //                     // console.log(timeToDecimal(element13.hours_actual) !== 0)
                    //                 }

                    //             }
                    //             // console.log(dataD)
                    //             this.setState({
                    //                 isLoading: false,
                    //                 data: dataD,

                    //             })
                    //         })
                    //         .catch(error => {
                    //             this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                    //                 variant: 'error'
                    //             });

                    //         });
                    // }

                });

            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)

            });


    }

    // fetchOps() {
    //     Service.get(`/bb/api/tender/project/?audit_status=10`, {
    //         headers: {
    //             Authorization: "Token " + token,
    //         },
    //     })
    //         .then(res => {
    //             var pids_ids = []
    //             var dataD = [];
    //             var toShw = '0'
    //             for (let i = 0; i < res.data.length; i++) {
    //                 const element = res.data[i];
    //                 if (element.is_subproject && element.subproject_pid === this.props.value.id) {
    //                     dataD.push(element)
    //                     toShw = toShw + ',' + element.id;
    //                 }
    //                 pids_ids.push(element.id)
    //             }
    //             this.setState({
    //                 data: dataD,
    //                 isLoading: false,
    //             });

    //             Service.patch('/bb/api/tender/tasks/rolesnodata/get_data/', {
    //                 headers: {
    //                     Authorization: "Token " + token,
    //                 },
    //                 data: pids_ids
    //             })
    //                 .then(res => {
    //                     // console.log(res.data)
    //                     this.setState({ data2: JSON.parse(JSON.stringify(res.data)), isLoading4: false, });
    //                 })
    //                 .catch(error => {
    //                     errorMessage(this.props, error)
    //                 });

    //             Service.patch('/bb/api/tender/rate/get_data/', {
    //                 headers: {
    //                     Authorization: "Token " + token,
    //                 },
    //                 data: pids_ids
    //             })
    //                 .then(res => {
    //                     // console.log(res.data)
    //                     if (res.data.length === 0) {
    //                         this.props.enqueueSnackbar('Goto Rates tab to initiate default rates!', {
    //                             variant: 'warning'
    //                         });
    //                     } else {

    //                         this.setState({
    //                             isLoading2: false,
    //                             rates: res.data,
    //                         });
    //                     }
    //                 })
    //                 .catch(error => {
    //                     errorMessage(this.props, error)
    //                 });

    //         })
    //         .catch(error => {
    //             errorMessage(this.props, error)
    //         });
    // }

    putOps(newData, oldData, resolve) {

        delete newData['from_date']
        delete newData['t0_date']

        Service.put("/bb/api/tender/project/" + newData.id + "/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: newData,
        })
            .then(res => {

                this.setState({ open: false, isLoading: true });
                // console.log(res.data)
                this.props.enqueueSnackbar('Row Updated', {
                    variant: 'success'
                });

                this.fetchOps();
                // // this.fetchOps();
                // const dataUpdate = [...this.state.data];
                // const index = oldData.tableData.id;
                // dataUpdate[index] = newData;
                // this.setState({ data: [...dataUpdate] })
                // let temp = this.props.projs
                // for (let i = 0; i < temp.length; i++) {
                //     const element = temp[i];
                //     if (newData.id === element.id) {
                //         element.approval_status = newData.approval_status
                //     }
                // }
            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });
    }

    dateParse(date) {
        var formatDate = moment(date).format('DD/MM/YYYY');
        // console.log(formatDate)
        if (formatDate === 'Invalid date') {
            return 'NA';
        } else {
            return formatDate;
        }
    }

    refreshData(updateCount) {
        for (let i = 0; i < this.state.data.length; i++) {
            const element = this.state.data[i];
            if (element.id === updateCount.ds) {
                element.pending_date_request = updateCount.pending_date_request
                this.props.updateCount(updateCount)
                break
            }
        }
    }

    openSubTender(data) {
        this.setState({ subTenderData: data }, () => { this.setState({ openSubTender: true }) })
    }

    render() {
        const { classes } = this.props;

        const handleSubTenderClose = () => {
            this.setState({ openSubTender: false, subTenderData: null })
        };

        const handleClose2 = () => {
            this.setState({ openCalc: false, });
        };
        const handleClose5 = () => {
            this.setState({ openIndiviRate: false, });
        };
        const handleDialogClose = () => {
            this.setState({
                openTimesheetReport: false
            })
        };

        const handleDateChangeReqClose = () => {
            this.setState({
                dateRquestDialog: false,
            })
        };

        const approval_lookup = { 0: 'Not Approved', 1: 'Approved' }
        return (
            <div>
                {(this.state.isLoading) ? (
                    <LoopCircleLoading color={getPrimaryColor()} />
                ) : (

                    <div>

                        <MaterialTable
                            title="Deliverables Schedule"
                            columns={[
                                {
                                    title: 'Type', field: 'type',
                                    lookup: { 0: 'Budget', 1: 'Change', },
                                    editable: 'never'
                                },
                                {
                                    title: 'Approval Status', field: 'approval_status',
                                    lookup: approval_lookup,
                                    render: (rowData) => rowData.type === 0 ? '-' : approval_lookup[rowData.approval_status],
                                    // editable : 'onUpdate'  
                                },
                                {
                                    title: 'Deliverables Schedule', field: 'name', headerStyle: { minWidth: 300 },
                                    cellStyle: { minWidth: 300 }, editable: 'never'
                                },

                                // {
                                //     title: 'Baseline Finish', field: 'to_date',
                                //     render: rowData => this.dateParse(rowData.to_date),
                                //     editable: 'never'
                                // },
                                {
                                    title: 'Estimated Finish', field: 'to_date',
                                    render: rowData => this.dateParse(rowData.to_date),
                                    // defaultFilter: this.findFilterValue("to_date"),
                                    customFilterAndSearch: (term, rowData) => {
                                        return this.dateParse(rowData.to_date).toLowerCase().includes(term.toLowerCase())
                                    }
                                },
                                {
                                    title: 'Budget Hours', field: 'budget_hours',
                                    // render: rowData => <Typography>{this.hourCalc(rowData)}</Typography>,
                                    editable: 'never'
                                },
                                {
                                    title: 'Hours to date', field: 'actual_hours',
                                    render: rowData => (rowData.actual_hours || rowData.actual_hours === 0) ? (rowData.actual_hours) : (<CircularProgress style={{ height: '15px', width: '15px' }} />),
                                    editable: 'never',
                                    filtering: false
                                },
                                {
                                    title: "Hours to come", field: 'dummy',
                                    render: rowData => (rowData.actual_hours || rowData.actual_hours === 0) ? (((rowData.revised_hours - rowData.actual_hours) < 0) ? (0) : (rowData.revised_hours - rowData.actual_hours)) : (<CircularProgress style={{ height: '15px', width: '15px' }} />),
                                    editable: 'never',
                                    hidden: this.props.audit_status === 13,
                                    filtering: false

                                },
                                {
                                    title: 'Hours at completion', field: 'revised_hours',
                                    // render: rowData => <Typography>{this.hourCalc2(rowData)}</Typography>,
                                    editable: 'never'
                                },
                                {
                                    title: 'Variance (in Hours)', filed: 'dummy',
                                    render: rowData => rowData.revised_hours - rowData.budget_hours,
                                    editable: 'never'
                                },

                                // {
                                //     title: 'Anticipated Final Pain (-) / Gain (+)', filed: 'dummy',
                                //     render: rowData => <Typography>0</Typography>,
                                //     editable: 'never'
                                // }
                            ]}
                            data={this.state.data}
                            actions={[
                                rowData => ({
                                    icon: () => <WebIcon />,
                                    tooltip: 'Planner',
                                    onClick: (event, rowData) => this.openSubTender(rowData),
                                }),
                                rowData => ({
                                    icon: 'credit_card',
                                    tooltip: "Special Rate",
                                    disabled: !(((this.props.value.tenderbid_data.pdm === user.id) || (access && access.access && access.access.l4a.l4a_z)) && this.props.audit_status !== 13),
                                    onClick: (event, rowData) => { this.setState({ openIndiviRate: true, individualRateData: rowData }) },
                                }),
                                rowData => ({
                                    icon: 'launch',
                                    tooltip: 'Task Overview',
                                    onClick: (event, rowData) => { this.setState({ allData: rowData, openCalc: true, }) },
                                }),
                                rowData => ({
                                    icon: 'feed',
                                    tooltip: "Timesheet Report",
                                    onClick: (event, rowData) => {
                                        this.setState({
                                            selectedDS: rowData,
                                            openTimesheetReport: true
                                        })
                                    },
                                }),
                                rowData => ({
                                    icon: () => <Badge style={{ margin: rowData.pending_date_request > 0 ? '7px' : '0px', marginLeft: '0px' }} max={9} badgeContent={rowData.pending_date_request} color="primary">
                                        <DateRangeIcon />
                                    </Badge>,
                                    tooltip: "DateChange Request",
                                    hidden: !((this.props.value.tenderbid_data.pdm === user.id) || (this.props.value.tenderbid_data.region_hoe.id === user.id) || !([2, 5].includes(this.props.tabSelected))),
                                    // hidden: [2, 5].includes(this.props.tabSelected),
                                    onClick: (event, rowData) => { this.setState({ dateRquestDialog: true, selectedDS: rowData }) },
                                }),

                            ]}
                            options={{
                                paging: false,
                                padding: "dense",
                                pageSize: this.state.data.length,
                                pageSizeOptions: [10, 20, 50],
                                doubleHorizontalScroll: true,
                                headerStyle: {
                                    backgroundColor: getDarkMode() === 'dark' ? '#4f7e76' : '#AFD6CB',
                                }
                            }}
                            style={{ backgroundColor: getDarkMode() === 'dark' ? '#4f7e76' : '#AFD6CB' }}
                            editable={{
                                isEditable: (rowData) => (rowData.audit_status !== 13 && !this.state.enableEdit && rowData.type && ((this.props.value.tenderbid_data.pdm === user.id && access && access.access && access.access.l4a.l4a_b) || (access && access.access && access.access.l4a.l4a_e))),
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            this.setState(prevState => {
                                                const data = [...prevState.data];
                                                data[data.indexOf(oldData)] = newData;
                                                this.putOps(newData, oldData)
                                                return { ...prevState, data };
                                            })
                                            resolve();
                                        }, 500);
                                    }),
                            }}

                        />

                        <Dialog fullScreen open={this.state.openCalc} onClose={handleClose2} TransitionComponent={Transition}>
                            <AppBar className={classes.appBar}>
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={handleClose2} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" className={classes.title}>
                                        {this.props.value.tender_name + " --> " + this.state.allData.name + " (Type: " + (this.state.allData.type === 0 ? "Budget" : "Change") + ")"}
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            <AllTenderData value={this.state.allData} tender={this.props.value} tabSelected={this.props.tabSelected} allDataTabs={this.props.allDataTabs} project_all={this.props.allDataTabs.includes(this.props.tabSelected)} />
                        </Dialog>

                        <Dialog open={this.state.openIndiviRate} onClose={handleClose5}
                            maxWidth={"xl"}
                            fullWidth
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description" >

                            {/* <DialogTitle id="alert-dialog-title">{}</DialogTitle> */}
                            <DialogContent>
                                <SpecialRateTab value={this.state.individualRateData} name={this.state.individualRateData.name} />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose5} color="primary">
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={this.state.openTimesheetReport}
                            maxWidth={"lg"}
                            onClose={handleDialogClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"Timesheet Report"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Report will shared via E-mail
                                </DialogContentText>
                                <UserTimesheetReport teamUsers={false} userEnabled={false} ds={this.state.selectedDS.id} project_all={this.props.allDataTabs.includes(this.props.tabSelected)} />
                            </DialogContent>
                            <DialogActions>

                                <Button onClick={handleDialogClose} color="primary" autoFocus>
                                    Close
                                </Button>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={this.state.dateRquestDialog}
                            maxWidth={"xl"}
                            fullWidth
                            onClose={handleDateChangeReqClose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <Box display="flex" flexDirection="row-reverse">
                                <Button color='primary' onClick={handleDateChangeReqClose}>Close</Button>
                            </Box>
                            <DialogContent>
                                <DateChangeReqManage
                                    dsLevel={this.state.selectedDS}
                                    refreshData={this.refreshData}
                                />
                            </DialogContent>
                        </Dialog>

                        <Dialog fullScreen open={this.state.openSubTender} aria-labelledby="customized-dialog-title" TransitionComponent={Transition}>
                            <AppBar position="static">
                                <Toolbar>
                                    <IconButton edge="start" color="inherit" onClick={handleSubTenderClose} aria-label="close">
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography variant="h6" >
                                        {this.state.subTenderData ? this.state.subTenderData.name : null}
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                            {/* <DialogContent> */}
                            <MainTenderSubTasks ds_id={this.state.subTenderData ? this.state.subTenderData.id : null} />
                            {/* </DialogContent> */}
                        </Dialog>
                    </div>
                )}
            </div>

        )
    }
}

// function addZeroes(num) {
//     const dec = num.toString().split('.')[1]
//     const len = dec ? 1 : null
//     if (len) {
//         return parseFloat(Number(num).toFixed(len))
//     } else {
//         return parseFloat(Number(num))
//     }
// }

// function timeToDecimal(t) {
//     var arr = t.split(':');
//     var dec = parseInt((arr[1] / 6) * 10, 10);

//     return addZeroes(parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec));
// }

export default withStyles(useStyles)(withSnackbar(SecuredDeliSch));