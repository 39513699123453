import React, { Component } from 'react'
import Service from "../../Networking/networkutils";
import MaterialTable from 'material-table';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from '@material-ui/core';
// import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import { withSnackbar } from 'notistack';
// import User3W from '../UserRoleMapping/User3W';
import { withStyles } from '@material-ui/core/styles';
import { getCookie } from '../../Helper/CookieHelper';
import ReactGA from 'react-ga';
import { blue } from '@material-ui/core/colors';
import { errorMessage } from '../../Helper/ErrorMessage';
// import AdditionalHourRequest from './AssignRoles/AdditionalHourRequest';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Alert } from '@material-ui/lab';
import { CoffeeLoading } from 'react-loadingg';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import SecuredProjectRoles from '../SecuredProjectRoles';
import AdditianalHoursManage from '../AdditianalHoursManage';

const token = getCookie("bb_ts_token");
const user = JSON.parse(getCookie("bb_ts_user"));
// var autocompdata = '';
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    avatar: {
        backgroundColor: blue[100],
        color: blue[600],
    },
});

// const DialogTitle = withStyles(styles)((props) => {
//     const { children, classes, onClose, ...other } = props;
//     return (
//         <MuiDialogTitle disableTypography className={classes.root} {...other}>
//             <Typography variant="h6">{children}</Typography>
//             {onClose ? (
//                 <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
//                     <CloseIcon />
//                 </IconButton>
//             ) : null}
//         </MuiDialogTitle>
//     );
// });
var waitData = false;

class TaskLevelAllocation2 extends Component {

    constructor(props) {
        super(props)
        this.state = {
            users: [],
            usersAll: [],
            data: [],
            isLoading: true,
            open: false,
            deliValue: {},
            roleUid: '0',
            openWho: false,
            pdmData: [],
            bid_data: {},
            hourReqButton: true,
            splPdm: false,
            pdmToRecv: null,
            isLoadingTable: false,
            roleActData: {},
            taskRoles: [],
            userListData: [],
            selected_role: null,
            selected_user: null,
            selected_hours: 0,
            actualData: null,
            usersData: props.usersData,
            est_hours: 0,
            rev_hours: 0,
            isLoadingHours: true,
            pendingHourRequest: [],
            openHourRequest: false,
            allUsers: [],
            searchBy: 'role',
            openCoffee: true,
            roleChangeDisabled: false,
            userTaskRoles: [],
            showAlert: false,
            openAddRole: false,
            country: null,
            openManageTaskRoles: false,
            addRoleDisabled: false,
            submitRoleDisabled: false,
        }
    }

    componentDidMount() {
        this.fetchOps();
    }

    fetchOps() {

        // console.log(this.props.team)
        this.getEstHours()

        this.setState({
            isLoading: true
        })

        var pageView = window.location.pathname + "/#TaskLevelAllocation";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);
        Service.get('/bb/api/tender/user/task/get_task_allocations/?task_id=' + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)

                // var comData = [];
                // for (let i = 0; i < this.props.team.length; i++) {
                //     const element = this.props.team[i];
                //     var goon = true;
                //     for (let j = 0; j < res.data.length; j++) {
                //         const element1 = res.data[j];
                //         if (element1.user_id === element.uid) {
                //             goon = false;
                //         }
                //     }
                //     if (goon) {
                //         comData.push(
                //             {
                //                 type: 100,
                //                 user_data: element.uid_data
                //             }
                //         )
                //     }
                // }

                // console.log(res.data)

                this.setState({ data: res.data, isLoading: false, isLoadingTable: false, }, () => {

                    // ## Not needed if below code is uncommented
                    this.getPendingRequest(this.props.value.id)

                    // var viewPid = []
                    // for (let i1 = 0; i1 < res.data.length; i1++) {
                    //     const element = res.data[i1];
                    //     viewPid.push({ 'com_ttid': this.props.value.id, 'com_uid': element.user_id })
                    // }
                    // if (viewPid.length !== 0) {
                    //     Service.patch("/bb/api/team/tasks/get_acthours/", {
                    //         headers: {
                    //             Authorization: "Token " + token,
                    //         },
                    //         data: viewPid,
                    //     })
                    //         .then(res12 => {

                    //             for (let j = 0; j < res12.data.length; j++) {
                    //                 const element13 = res12.data[j];
                    //                 for (let i = 0; i < res.data.length; i++) {
                    //                     const element12 = res.data[i];
                    //                     if (element12.user_id === element13.id2) {
                    //                         element12.actual_hours = timeToDecimal(element13.hours_actual)
                    //                         element12.inprogress_hours = timeToDecimal(element13.hours_ongoing)
                    //                         element12.total_alloc_hours = element13.allocated

                    //                     }
                    //                     // console.log(timeToDecimal(element13.hours_actual) !== 0)
                    //                 }

                    //             }
                    //             // console.log(dataD)
                    //             this.setState({

                    //                 data: [...res.data, ...comData]

                    //             })
                    //             this.getPendingRequest(this.props.value.id)
                    //         })
                    //         .catch(error => {
                    //             this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                    //                 variant: 'error'
                    //             });

                    //         });
                    // }

                })
            })
            .catch(error => {
                errorMessage(this.props, error)
            });

        Service.patch("/bb/api/team/tasks/get_acthours/", {
            headers: {
                Authorization: "Token " + token,
            },
            data: [{ ttid: this.props.value.id }],
        })
            .then(res12 => {
                // console.log(res12.data)

                this.setState({

                    actualData: res12.data[0]

                })
            })
            .catch(error => {
                this.props.enqueueSnackbar('Hours utilized data unavailable.', {
                    variant: 'error'
                });

            });

    }

    getPendingRequest(taskid) {
        Service.get("/bb/api/tender/hours/request/pending_count_task/?task=" + taskid, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)

                this.setState({
                    pendingHourRequest: res.data
                })
            })
            .catch(error => {
                // console.log(error)
                errorMessage(this.props, error)
            });
    }

    getEstHours() {
        Service.get('/bb/api/tender/tasks/roles/?tid=' + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {

                var hours = 0, hours1 = 0
                for (let i = 0; i < res.data.length; i++) {
                    const element = res.data[i];
                    hours = hours + element.hours
                    hours1 = hours1 + element.hours + element.req_hours
                }
                // console.log(hours)
                this.setState({
                    est_hours: hours,
                    rev_hours: hours1,
                    isLoadingHours: false,
                })

            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    fetchTaskDetails() {

        Service.get('/bb/api/tender/tasks/roles/get_roles_data/?task_id=' + this.props.value.id, {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                // console.log(res.data)
                this.setState({
                    openCoffee: false,
                    taskRoles: res.data
                }, () => {
                    if (this.state.selected_user) {
                        // for (let i = 0; i < this.state.taskRoles.length; i++) {
                        //     const element = this.state.taskRoles[i];
                        //     if (element.t_role === this.state.selected_role.id && element.country === this.state.country) {
                        //         this.setState({ selected_role: element })
                        //     }
                        // }
                        this.allUsersChange(null, this.state.selected_user)
                    }
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    getAllUsers() {
        this.setState({ openCoffee: true })
        Service.get('/bb/api/users/active_users/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                let userIds = []
                for (let i = 0; i < this.state.data.length; i++) {
                    const user = this.state.data[i];
                    if (!userIds.includes(user.user_id)) {
                        userIds.push(user.user_id)
                    }
                }
                let filteredUsers = []
                for (let j = 0; j < res.data.length; j++) {
                    const users = res.data[j];
                    if (!userIds.includes(users.id)) {
                        filteredUsers.push(users)
                    }
                }
                this.setState({
                    allUsers: filteredUsers,
                    openCoffee: false,
                    userTaskRoles: this.state.taskRoles
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    postData(userdata) {
        var pageView = window.location.pathname + "/#TenderAddAndEdit";
        ReactGA.set({ page: pageView, });
        ReactGA.pageview(pageView);
        if (userdata === '') {
            this.props.enqueueSnackbar('Select a user to continue!', {
                variant: 'warning'
            });
        } else {
            this.setState({ isLoading: true, submitRoleDisabled: true })

            if (this.state.selected_role && this.state.selected_user) {
                if (this.state.selected_hours > 0) {
                    var postData = {
                        task_id: this.props.value.id,
                        role_id: this.state.selected_role.t_role,
                        task_role_id: this.state.selected_role.id,
                        wbs_id: this.props.value.t_ohlwbs,
                        t_pid: this.props.value.t_pid,
                        user_id: this.state.selected_user.id,
                        user_email: this.state.selected_user.email,
                        created_by: user.email,
                        planed_hours: this.state.selected_hours
                    }
                    Service.post('/bb/api/tender/user/task/user_task_allocation/', {
                        headers: {
                            Authorization: "Token " + token,
                        },
                        data: postData,
                    })
                        .then(res => {
                            waitData = false;
                            var data = this.state.data;
                            // data.push(res.data);
                            // autocompdata = '';
                            this.setState({ data: data, isLoading: false, openAdd: false, selected_hours: 0, submitRoleDisabled: false })
                            this.props.enqueueSnackbar('Data Added Successfully!', {
                                variant: 'success'
                            });
                            this.fetchOps();
                            this.getAllUsers();
                            this.fetchTaskDetails();
                            // this.setState({ data: JSON.parse(JSON.stringify(res.data)), isLoading: false })
                        })
                        .catch(error => {
                            if (error && error.status === 406) {
                                this.props.enqueueSnackbar(error.data.message, {
                                    variant: 'warning'
                                });
                                this.setState({ isLoading: false, submitRoleDisabled: false })
                            } else {
                                waitData = false;
                                errorMessage(this.props, error)
                                this.setState({ isLoading: false, submitRoleDisabled: false })
                            }
                        });
                } else {
                    this.props.enqueueSnackbar('Hours must be greater than 0!', {
                        variant: 'warning'
                    });
                    this.setState({ isLoading: false, submitRoleDisabled: false })
                }
            } else {
                this.setState({ isLoading: false, submitRoleDisabled: false })
                this.props.enqueueSnackbar('Fill all fields!', {
                    variant: 'warning'
                });
            }

        }

    }

    addData() {
        let fields = ''

        if (!this.state.selected_role) {
            fields = "Role"
        }
        if (!this.state.selected_hours) {
            fields += !fields ? 'Hours' : ', Hours'
        }
        if (!this.state.country) {
            fields += !fields ? 'Country' : ", Country"
        }

        if (!fields) {
            var roleExist = false;
            this.setState({ addRoleDisabled: true })
            for (let i = 0; i < this.state.taskRoles.length; i++) {
                const element = this.state.taskRoles[i];
                if (element.t_role === this.state.selected_role.id && element.country === this.state.country) {
                    roleExist = true;
                    break;
                }
            }
            if (!roleExist) {
                var postData = {};
                postData.t_pid = this.props.value.t_pid;
                postData.t_wbs = this.props.value.t_ohlwbs;
                postData.t_tasks = this.props.value.id;
                postData.t_role = this.state.selected_role.id;
                postData.remark = '';
                postData.created_by = user.email;
                postData.name = this.state.selected_role.name;
                postData.grade = this.state.selected_role.grade.toLowerCase();
                postData.hours = 0;
                postData.req_hours = this.state.selected_hours;
                postData.country = this.state.country;
                postData.goon = 1;
                postData.hours_rev_history = [{
                    user: user.id,
                    user_name: user.first_name + " " + user.last_name,
                    start: this.state.selected_hours,
                    date_modified: new Date(),
                    rev_new: this.state.selected_hours,
                }]

                Service.post("/bb/api/tender/tasks/rolesrevhistory/", {
                    headers: {
                        Authorization: "Token " + token,
                    },
                    data: postData,
                })
                    .then(res => {
                        this.setState({
                            isLoading: false,
                            openAddRole: false,
                            showAlert: false,
                            addRoleDisabled: false
                        })
                        this.props.enqueueSnackbar('Roles updated successfully', {
                            variant: 'success'
                        });
                        this.fetchTaskDetails();
                    })
                    .catch(error => {
                        console.log(error)
                        errorMessage(this.props, error)
                        this.setState({ addRoleDisabled: false })
                    });
            } else {
                this.props.enqueueSnackbar('Role with same country configuration already exist!', {
                    variant: 'warning'
                });
            }
        }
        else {
            this.props.enqueueSnackbar(fields + " is Empty!", {
                variant: 'warning'
            });
        }
    }

    // givePlanned(data, asdata) {
    //     var tot_planned = 0
    //     for (let i = 0; i < data.length; i++) {
    //         const element = data[i];
    //         if (asdata.task_role_id === element.task_role_id && asdata.id !== element.id) {
    //             tot_planned = tot_planned + element.planed_hours
    //         }
    //     }
    //     return tot_planned + asdata.planed_hours
    // }

    putData(asdata, oldData, alldata) {
        this.setState({ isLoading: true, openAdd: false })
        Service.put('/bb/api/tender/user/task/' + asdata.id + '/', {
            headers: {
                Authorization: "Token " + token,
            },
            data: asdata,
        })
            .then(res => {

                this.props.enqueueSnackbar('Edited Successfully!', {
                    variant: 'success'
                });
                this.fetchOps();
                this.getAllUsers();
                this.fetchTaskDetails();
                // this.setState({ data: JSON.parse(JSON.stringify(res.data)), isLoading: false })
            })
            .catch(error => {
                this.fetchOps();
                if (error && error.status === 416) {
                    this.props.enqueueSnackbar(error.data, {
                        variant: 'warning'
                    });
                } else if (error && error.status === 406) {
                    this.props.enqueueSnackbar(error.data, {
                        variant: 'warning'
                    });
                } else {
                    errorMessage(this.props, error)
                }
            });
    }

    deleteData(asdata) {
        // this.setState({ isLoading: true })
        Service.delete('/bb/api/tender/user/task/delete_user_task/?user_task=' + asdata.id, {
            headers: {
                Authorization: "Token " + token,
            },

        })
            .then(res => {
                this.setState({ isLoading: false })
                this.props.enqueueSnackbar('Deleted Successfully!', {
                    variant: 'success'
                });
                this.fetchOps();
                this.getAllUsers();
                this.fetchTaskDetails();
                // this.setState({ data: JSON.parse(JSON.stringify(res.data)), isLoading: false })
            })
            .catch(error => {
                errorMessage(this.props, error)
                this.setState({ isLoading: false })
            });
    }

    // onTagsChange = (event, values) => {
    //     // console.log(values)
    //     autocompdata = values;
    // }

    handleUserSelect(e) {
        this.setState({ isLoadingTable: true });

        var ext = false;
        for (let i = 0; i < this.state.data.length; i++) {
            const element = this.state.data[i];
            if (element.user_id === e.id) {
                ext = true;
                break;
            }
        }
        if (!ext && !waitData) {
            waitData = true;
            // this.setState({ openWho: false })
            this.postData(e);
        } else if (ext) {
            this.props.enqueueSnackbar('User already mapped!', {
                variant: 'warning'
            });
        } else if (waitData) {
            this.props.enqueueSnackbar('Please wait!', {
                variant: 'warning'
            });
        }
    }

    checkTeamUser(e) {
        return true
    }

    checkPDM() {
        if (this.state.pdmData.length === 0) {
            if (this.state.bid_data.pdm) {
                this.setState({
                    splPdm: false,
                    pdmToRecv: this.state.bid_data.pdm_data,
                    hourReqButton: false
                })
            }
        } else {
            var gotit = false
            for (let i = 0; i < this.state.pdmData.length; i++) {
                const element = this.state.pdmData[i];
                if (element.pdm_type === this.props.value.t_tasks_data.wbs.type) {
                    this.setState({
                        splPdm: true,
                        pdmToRecv: element.uid_data,
                        hourReqButton: false
                    })
                    gotit = true;
                    break;
                }
            }
            if (!gotit) {
                if (this.state.bid_data.pdm) {
                    this.setState({
                        splPdm: false,
                        pdmToRecv: this.state.bid_data.pdm_data,
                        hourReqButton: false
                    })
                }
            }
        }
    }

    getDefaultRoles() {
        this.setState({ openAddRole: true })
        Service.get('/bb/api/roles/', {
            headers: {
                Authorization: "Token " + token,
            },
        })
            .then(res => {
                this.setState({ defaultRoles: res.data }, () => {
                    if (this.state.selected_user) {
                        for (let i = 0; i < this.state.defaultRoles.length; i++) {
                            const element = this.state.defaultRoles[i];
                            if (this.state.selected_user.role_id === element.id) {
                                this.setState({ selected_role: element })
                                break
                            }
                        }
                    }
                })
            })
            .catch(error => {
                console.log(error)
                errorMessage(this.props, error)
            });
    }

    onRoleChange = (event, values) => {
        this.setState({ selected_role: values }, () => {
            if (values && values.users) {
                let userIds = []
                for (let i = 0; i < this.state.data.length; i++) {
                    const user = this.state.data[i];
                    if (!userIds.includes(user.user_id)) {
                        userIds.push(user.user_id)
                    }
                }
                let filteredUsers = []
                for (let j = 0; j < values.users.length; j++) {
                    const users = values.users[j];
                    if (!userIds.includes(users.id)) {
                        filteredUsers.push(users)
                    }
                }
                this.setState({ userListData: filteredUsers })
            } else {
                this.setState({ userListData: [] })
            }

        })
    }

    onDefaultRoleChange = (event, values) => {
        this.setState({ selected_role: values ? values : null })
    }

    onUserChange = (event, values) => {
        this.setState({ selected_user: values }, () => {
        })
    }

    allUsersChange = (event, values) => {
        if (values) {
            this.setState({ selected_user: values, selected_role: null }, () => {
                // debugger
                var roles = []
                for (let i = 0; i < this.state.taskRoles.length; i++) {
                    const element = this.state.taskRoles[i];
                    if (this.state.selected_user.role_id === element.t_role) {
                        roles.push(element)
                    }
                }
                if (roles.length === 0) {
                    this.setState({ showAlert: true, roleChangeDisabled: true })
                } else if (roles.length === 1) {
                    this.setState({ selected_role: roles[0], roleChangeDisabled: true, showAlert: false })
                } else if (roles.length > 0) {
                    this.setState({ userTaskRoles: roles, showAlert: false })
                }
            })
        } else {
            this.setState({ selected_user: null, showAlert: false, selected_role: null, roleChangeDisabled: false, })
        }
    }

    countryMatchCheck() {
        if (this.state.selected_role?.country === 'in' && this.state.selected_user?.office_loc === 1) {
            return false
        } else if (this.state.selected_role?.country === 'uk' && this.state.selected_user?.office_loc === 0) {
            return false
        } else if (this.state.selected_role?.country === 'sl' && this.state.selected_user?.office_loc === 2) {
            return false
        } else {
            return true
        }
    }

    returnWarningText() {

        if (this.state.selected_user && this.state.selected_role) {
            var text = "You are selecting the "
            if (this.state.selected_user?.office_loc === 1) {
                text += 'India user for '
            } else if (this.state.selected_user?.office_loc === 0) {
                text += 'UK user for '
            } else if (this.state.selected_user?.office_loc === 2) {
                text += 'Sri Lanka user for '
            }

            if (this.state.selected_role?.country === 'in') {
                text += "India role."
            } else if (this.state.selected_role?.country === 'uk') {
                text += "UK role."
            } else if (this.state.selected_role?.country === 'sl') {
                text += "Sri Lanka role."
            }
            return text

        }

    }
    
    render() {
        // const { classes } = this.props;

        // const handleClose = () => {
        //     this.setState({ open: false })
        // };

        const handleCountryChange = (event) => {
            this.setState({ country: event.target.value });
        };

        const handleAddClose = () => {
            this.setState({ openAdd: false, selected_hours: 0, })
        };

        const handleManageTaskRolesClose = () => {
            this.setState({ openManageTaskRoles: false }, () => { this.fetchTaskDetails() })
        };

        const handleAddRolesClose = () => {
            this.setState({ openAddRole: false, selected_role: null, country: null })
        };
        // const handleWhoClose = () => {
        //     this.setState({
        //         openWho: false,

        //     })
        // };

        const handleHourRequestClose = () => {

            this.setState({
                openHourRequest: false,

            })
            this.getPendingRequest(this.props.value.id)
        };

        const handleHoursChange = (e) => {
            this.setState({ selected_hours: e.target.value, })
        };

        const handleToggle = (e, newValue) => {
            if (newValue !== null) {
                this.setState({ searchBy: e.target.value, selected_user: null, selected_role: null, showAlert: false }, () => {
                    if (this.state.searchBy === 'user' && this.state.allUsers.length === 0) {
                        this.getAllUsers()
                    }
                })
            }
        };

        return (
            <div>
                {(this.state.pendingHourRequest.length) ? (
                    <div style={{ display: 'flex' }}>
                        <Alert severity="warning"
                            action={
                                <Button color="inherit" size="small"
                                    onClick={() => this.setState({ openHourRequest: true })}
                                >
                                    View Requests
                                </Button>
                            }
                        >{this.state.pendingHourRequest.length + " additional hour request for this task is pending"}</Alert>
                    </div>
                ) : (null)}
                <MaterialTable
                    title={
                        <div>
                            <Box fontSize={16}>{(this.state.actualData) ? "Estimated Hours: " + this.state.est_hours + ",  Revised Hours: " + this.state.rev_hours : null}</Box>
                            <Box fontSize={16}>{(this.state.actualData) ? "Unallocated Hours: " + (this.state.rev_hours - this.state.actualData.allocated) + ",  Allocated Hours: " + this.state.actualData.allocated + ",   Actual and Inprogress Hours: " + (timeToDecimal(this.state.actualData.hours_actual) + timeToDecimal(this.state.actualData.hours_ongoing)) + ", Unutilized Hours: " + (this.state.actualData.allocated - (timeToDecimal(this.state.actualData.hours_actual) + timeToDecimal(this.state.actualData.hours_ongoing))) : null}</Box>
                        </div>
                    }
                    columns={[
                        {
                            title: 'User', field: 'first_name', editable: 'never',
                            render: rowData => <Typography>{rowData.first_name + " " + rowData.last_name}</Typography>
                        },
                        {
                            title: 'Allocated Roles', field: 'role_id__name',
                        },
                        {
                            title: 'Type', field: 'assign_type',
                            lookup: { 0: 'Assigned', 1: 'Reviewer', 100: 'Not Assigned', }
                        },
                        // {
                        //     title: 'Work Estimated Start', field: 'start', type: 'date', default: this.props.value.from_date,
                        // },
                        // {
                        //     title: ' Work Estimated End', field: 'end', type: 'date', default: this.props.value.to_date,
                        // },
                        {
                            title: 'Actual and Inprogress Hours/Total Allocated', field: 'act_and_inprogress', editable: 'never',
                            render: rowData => rowData.act_and_inprogress + "/" + rowData.total_alloc_hours
                        },
                        {
                            title: 'Unutilized Hours', field: 'balance_hours', editable: 'never',
                        },
                        {
                            title: 'Allocated Hours', field: 'planed_hours', type: 'numeric',
                        },
                        {
                            title: 'Allocation Cost', field: 'allocation_cost', editable: 'never', type: 'numeric',
                            render: rowData => new Intl.NumberFormat('en-UK', { style: 'currency', currency: 'GBP' }).format(rowData.allocation_cost)
                        },
                    ]}
                    data={this.state.data}
                    options={{
                        padding: "dense",
                        pageSize: 5,
                        actionsColumnIndex: -1,
                        paging: false
                        // filtering: true
                    }}
                    isLoading={this.state.isLoadingTable || this.state.isLoading || this.state.isLoadingHours}
                    actions={
                        [
                            {
                                icon: 'add_box',
                                tooltip: 'Add User',
                                isFreeAction: true,
                                disabled: this.props.value.t_wbs_data.pid_data.audit_status === 13,
                                onClick: () => {
                                    this.fetchTaskDetails();
                                    this.setState({ openAdd: true, selected_role: null, selected_user: null, selected_hours: 0, openCoffee: true, searchBy: 'role', })
                                },
                            },
                        ]
                    }
                    editable={{
                        isEditable: rowData => (this.props.value.t_wbs_data.pid_data.audit_status !== 13 && rowData.id && this.checkTeamUser(rowData)),
                        onRowUpdate: (newData, oldData) =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    this.setState({ save: false });
                                    if (oldData && this.checkTeamUser(oldData)) {
                                        this.setState(prevState => {
                                            const data = [...prevState.data];
                                            delete newData['actual_hours']
                                            data[data.indexOf(oldData)] = newData;
                                            this.putData(newData, oldData, data);
                                            return { ...prevState, data };
                                        });

                                    }
                                }, 1000);
                            }),
                        isDeletable: rowData => (rowData.id && this.checkTeamUser(rowData) && rowData.planed_hours <= 0),
                        onRowDelete: oldData =>
                            new Promise(resolve => {
                                setTimeout(() => {
                                    resolve();
                                    this.setState({ save: false });
                                    this.setState(prevState => {
                                        const data = [...prevState.data];
                                        data.splice(data.indexOf(oldData), 1);
                                        this.deleteData(oldData);
                                        return { ...prevState, data };
                                    });
                                }, 60);
                            }),
                    }}
                />

                <Dialog open={this.state.openAdd} fullWidth={true} maxWidth={this.state.showAlert ? "md" : "sm"} onClose={handleAddClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="customized-dialog-title" onClose={handleAddClose}>
                        <Box display={'flex'} flexDirection='row' alignItems={'center'}>
                            <Box fontWeight={500} fontSize='18px' marginRight={'10px'}>
                                Allocate User
                            </Box>
                            <ToggleButtonGroup
                                color="primary"
                                size="small"
                                // style={{ marginBottom: '3px' }}
                                value={this.state.searchBy}
                                onChange={handleToggle}
                                exclusive
                                aria-label="text formatting"
                            >
                                <ToggleButton value={"role"} aria-label="to_lead">
                                    Search by role
                                </ToggleButton>
                                <ToggleButton value={"user"} aria-label="to_em">
                                    Search by user
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        {this.state.openCoffee ?
                            <Box m={5} p={2}>
                                <CoffeeLoading />
                            </Box>
                            :
                            <Box>
                                {this.state.searchBy === 'role' ?
                                    <>
                                        <Box display="flex" flexDirection="row" m={2}>
                                            <Autocomplete
                                                id="'selected_role'"
                                                options={this.state.taskRoles}
                                                value={(this.state.selected_role === "") ? (null) : (this.state.selected_role)}
                                                getOptionLabel={(option) => option.name + ((option.country === "in") ? " - IND (" : (option.country === "sl") ? " - SL (" : " - UK (") + (option.hours + option.req_hours) + " hours)"}
                                                style={{ width: '100%' }}
                                                onChange={this.onRoleChange}
                                                renderInput={(params) => <TextField {...params} label="Select Role" variant="outlined" />}
                                            />
                                        </Box>
                                        <Box display="flex" flexDirection="row" m={2}>
                                            <Autocomplete
                                                id="'selected_user'"
                                                options={this.state.userListData}
                                                value={(this.state.selected_user === "") ? (null) : (this.state.selected_user)}
                                                getOptionLabel={(option) => option.last_name + ", " + option.first_name}
                                                style={{ width: '100%' }}
                                                onChange={this.onUserChange}
                                                renderInput={(params) => <TextField {...params} label="Select User" variant="outlined" />}
                                            />
                                        </Box>

                                        {this.countryMatchCheck() ?
                                            <Box m={2} fontSize={'12px'} color='red'>
                                                {this.returnWarningText()}
                                            </Box>
                                            : null}
                                    </> :
                                    <>
                                        <Box display="flex" flexDirection="row" m={2}>
                                            <Autocomplete
                                                id="'selected_user'"
                                                options={this.state.allUsers}
                                                value={(this.state.selected_user === "") ? (null) : (this.state.selected_user)}
                                                getOptionLabel={(option) => option.last_name + ", " + option.first_name}
                                                style={{ width: '100%' }}
                                                onChange={this.allUsersChange}
                                                renderInput={(params) => <TextField {...params} label="Select User" variant="outlined" />}
                                            />
                                        </Box>

                                        {this.state.showAlert ?
                                            <Box display="flex" flexDirection="row" m={2} justifyContent='right'>
                                                <Alert severity="warning" style={{ width: '100%' }}
                                                    action={
                                                        <Box display="flex" flexDirection={"column"}>
                                                            <Button variant={'outlined'} color="inherit" size="small" onClick={() => this.getDefaultRoles()}>
                                                                Add Role
                                                            </Button>
                                                            <Button variant={'outlined'} color="inherit" size="small" style={{ marginTop: '5px' }} onClick={() => this.setState({ openManageTaskRoles: true })}>
                                                                Manage task roles
                                                            </Button>
                                                        </Box>}>{this.state.selected_user ? this.state.selected_user?.role_name + " role " : "Primary Role "} of Selected User is not available at this Task. Please click 'Add Role' to add the role to task.</Alert>
                                            </Box> :
                                            <Box display="flex" flexDirection="row" m={2}>
                                                <Autocomplete
                                                    disabled={this.state.roleChangeDisabled}
                                                    id="'selected_role'"
                                                    options={this.state.userTaskRoles}
                                                    value={(this.state.selected_role === "") ? (null) : (this.state.selected_role)}
                                                    getOptionLabel={(option) => option.name + ((option.country === "in") ? " - IND (" : (option.country === "sl") ? " - SL (" : " - UK (") + (option.hours + option.req_hours) + " hours)"}
                                                    style={{ width: '100%' }}
                                                    onChange={this.onRoleChange}
                                                    renderInput={(params) => <TextField {...params} label="Select Role" variant="outlined" />}
                                                />
                                            </Box>}
                                        {this.countryMatchCheck() ?
                                            <Box m={2} fontSize={'12px'} color='red'>
                                                {this.returnWarningText()}
                                            </Box>
                                            : null}
                                    </>
                                }

                                <Box display="flex" flexDirection="row" m={2}>
                                    <TextField
                                        id="hours"
                                        type={'number'}
                                        label="Hours"
                                        variant="outlined"
                                        style={{ width: '100%' }}
                                        value={this.state.selected_hours}
                                        onChange={handleHoursChange}
                                    />
                                </Box>
                            </Box>
                        }


                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleAddClose} color="primary">
                            Close
                        </Button>

                        <Button disabled={this.state.submitRoleDisabled} onClick={() => this.postData()} color="primary">
                            Submit
                        </Button>

                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.openAddRole} fullWidth={true} maxWidth="sm" onClose={handleAddRolesClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="customized-dialog-title" onClose={handleAddRolesClose}>
                        Add Role for {this.state.selected_user ? (this.state.selected_user.last_name + ", " + this.state.selected_user.first_name) : null}
                    </DialogTitle>
                    <DialogContent>
                        <Box>
                            <Box display="flex" flexDirection="row" m={2}>
                                <Autocomplete
                                    id="'selected_role'"
                                    disabled={this.state.roleChangeDisabled}
                                    options={this.state.defaultRoles}
                                    value={(this.state.selected_role === "") ? (null) : (this.state.selected_role)}
                                    getOptionLabel={(option) => option.name}
                                    style={{ width: '100%' }}
                                    onChange={this.onDefaultRoleChange}
                                    renderInput={(params) => <TextField {...params} label="Select Role" variant="outlined" />}
                                />
                            </Box>
                            <Box display={'flex'} flexDirectio='row' m={2} >
                                <FormControl variant="outlined" style={{ width: '100%' }}>
                                    <InputLabel id="demo-simple-select-outlined-label">Country</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        value={this.state.country}
                                        onChange={handleCountryChange}
                                        label="Country"
                                    >
                                        <MenuItem value="in">India</MenuItem>
                                        <MenuItem value="uk">UK</MenuItem>
                                        <MenuItem value="sl">Srilanka</MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box display="flex" flexDirection="row" m={2} >
                                <TextField
                                    id="hours"
                                    type={'number'}
                                    label="Hours"
                                    variant="outlined"
                                    style={{ width: '100%' }}
                                    value={this.state.selected_hours}
                                    onChange={handleHoursChange}
                                />
                            </Box>

                        </Box>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={handleAddRolesClose} color="primary">
                            Close
                        </Button>

                        <Button disabled={this.state.addRoleDisabled} onClick={() => this.addData()} color="primary">
                            Add
                        </Button>

                    </DialogActions>
                </Dialog >

                <Dialog onClose={handleHourRequestClose} aria-labelledby="customized-dialog-title" maxWidth="xl" open={this.state.openHourRequest}>
                    <DialogContent>
                        <Box display="flex" flexDirection="row-reverse">
                            <Button color='primary' onClick={handleHourRequestClose}>Close</Button>
                        </Box>
                        <AdditianalHoursManage task={this.props.value.id} />
                    </DialogContent>
                </Dialog>

                <Dialog onClose={handleManageTaskRolesClose} aria-labelledby="customized-dialog-title" maxWidth="xl" open={this.state.openManageTaskRoles}>

                    <DialogContent>
                        <Box display="flex" flexDirection="row-reverse">

                            <Button color='primary' onClick={handleManageTaskRolesClose}>Close</Button>

                        </Box>
                        <SecuredProjectRoles value={this.props.value} task={this.props.value} rolesLookUp={this.props.allRoles} projectLook={this.props.projectLook} />
                    </DialogContent>
                </Dialog>

            </div >
        )
    }
}

function addZeroes(num) {
    const dec = num.toString().split('.')[1]
    const len = dec ? 1 : null
    if (len) {
        return parseFloat(Number(num).toFixed(len))
    } else {
        return parseFloat(Number(num))
    }
}

function timeToDecimal(t) {
    try {
        var arr = t.split(':');
        var dec = parseInt((arr[1] / 6) * 10, 10);

        return addZeroes(parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec));
    } catch (error) {
        return 0
    }

}

export default withStyles(styles)(withSnackbar(TaskLevelAllocation2));