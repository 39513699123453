import moment from "moment";
import { getDiscipline } from "./CommonThings";

export function getTimesheetReportColumns() {
    return [
        {
            title: 'First Name',
            field: 'uid__first_name',
            // render: rowData => rowData
        },
        {
            title: 'Last Name',
            field: 'uid__last_name',
            // render: rowData => rowData
        },
        {
            title: 'Emp. Code',
            field: 'uid__user_details__emp_code',
            render: rowData => rowData.uid__user_details__emp_code
        },
        {
            title: 'Emp. R12',
            field: 'uid__user_details__r12_emp_code',
            render: rowData => rowData.uid__user_details__r12_emp_code
        },
        {
            title: 'Emp. R12 Classification',
            field: 'uid__user_details__r12_class_code',
            render: rowData => rowData.uid__user_details__r12_class_code
        },
        {
            title: 'EDC/UK/SL',
            field: 'uid__office_loc',
            render: rowData => rowData.uid__office_loc === 1 ? "EDC" : rowData.uid__office_loc === 2 ? "SL" : "UK"
        },
        {
            title: 'Role',
            field: 'current_role_name',
            render: rowData => rowData.current_role_name
        },
        {
            title: 'Grade',
            field: 'current_role_grade',
            render: rowData => rowData.current_role_grade ? rowData.current_role_grade.toUpperCase() : "NA"
        },
        {
            title: 'Item ID',
            field: 'id',
            // render: rowData => rowData.uid__role_id__grade
        },
        {
            title: 'Date',
            field: 'start',
            render: rowData => moment(rowData.start).format('DD/MM/YYYY')
        },
        {
            title: 'Team',
            field: 'gid__name',
            // render: rowData => moment(rowData.start).format('DD/MM/YYYY')
        },
        {
            title: 'Project Name',
            field: 'ttenderid__from_tenderbid__opportunity',
            render: rowData => rowData.ttenderid__from_tenderbid__opportunity ? rowData.ttenderid__from_tenderbid__opportunity : 'Non Billable'
        },
        {
            title: 'Client',
            field: 'ttenderid__from_tenderbid__client__name',
            render: rowData => rowData.ttenderid__from_tenderbid__client__name ? rowData.ttenderid__from_tenderbid__client__name : 'Non Billable'
        },
        {
            title: 'Discipline',
            field: 'ttenderid__from_tenderbid__lead_dicipline',
            render: rowData => rowData.ttenderid__from_tenderbid__lead_dicipline ? getDiscipline()[rowData.ttenderid__from_tenderbid__lead_dicipline] : "Non Billable"
        },
        {
            title: 'DS Name',
            field: 'tpid__name',
            render: rowData => rowData.tpid__name ? rowData.tpid__name : (rowData.tid__task_category__name ? rowData.tid__task_category__name : "Non Billable")
        },
        {
            title: 'WBS Name',
            field: 'twbsid__name',
            render: rowData => rowData.twbsid__name ? rowData.twbsid__name : ''
        },
        {
            title: 'Task ID',
            field: 'ttid_id',
            render: rowData => rowData.ttid_id ? rowData.ttid_id : rowData.tid_id
        },
        {
            title: 'Task Name',
            field: 'ttid__decs',
            render: rowData => rowData.ttid__decs ? rowData.ttid__decs : rowData.ttid__name ? rowData.ttid__name : rowData.tid__name
        },
        {
            title: '% Complete',
            field: 'ttid__comp_percnt',
            editable: 'never',
            render: rowData => (rowData.ttid__comp_percnt || rowData.ttid__comp_percnt === 0) ? rowData.ttid__comp_percnt : 'NA'
        },
        {
            title: 'Billable/Non Billable',
            field: 'ttid__inc_evp',
            render: rowData => rowData.ttid__inc_evp === 0 ? "Non Billable" : rowData.ttid__inc_evp === 1 ? "Billable" : "Non Billable"
        },
        // {
        //     title: 'Type',
        //     field: 'ttid_id',
        //     render: rowData => rowData.ttid_id ? 'Available Workbook' : 'Non Billable'
        // },
        {
            title: 'Hours',
            field: 'hours',
            render: rowData => (parseFloat(rowData.hours) / 3600).toFixed(2)
        },
        // {
        //     title: 'Rate',
        //     field: 'ts_rate',
        //     render: rowData => rowData.ts_rate ? rowData.ts_rate : "NA"
        // },
        // {
        //     title: 'Amount',
        //     field: 'ts_cost',
        //     render: rowData => rowData.ts_cost ? rowData.ts_cost : "NA"
        // },
        {
            title: 'Comments',
            field: 'comments',
            // render: rowData => moment(rowData.start).format('DD/MM/YYYY')
        },
        {
            title: 'Status',
            field: 'is_approved',
            render: rowData => rowData.is_approved === 0 ? (rowData.is_submitted === 1 ? 'Submitted' : 'InProgress') : rowData.is_approved === 1 ? "Approved" : rowData.is_approved === 2 ? 'Rejected' : null
        },
        {
            title: 'BL Start',
            field: 'ttid__from_date',
            render: rowData => !rowData.ttid__from_date ? "NA" : moment(rowData.ttid__from_date).format('DD/MM/YYYY')
        },
        {
            title: 'BL Finish',
            field: 'ttid__to_date',
            render: rowData => !rowData.ttid__to_date ? "NA" : moment(rowData.ttid__to_date).format('DD/MM/YYYY')
        },
        {
            title: 'Act Start',
            field: 'ttid__act_exp_start',
            render: rowData => !rowData.ttid__act_exp_start ? "NA" : moment(rowData.ttid__act_exp_start).format('DD/MM/YYYY')
        },
        {
            title: 'Act Finish',
            field: 'ttid__act_exp_finish',
            render: rowData => !rowData.ttid__act_exp_finish ? "NA" : moment(rowData.ttid__act_exp_finish).format('DD/MM/YYYY')
        },
    ]
}