
import Login from './Login/Login'
import './App.css';
import React, { Component } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
// import SignUp from './Login/SignUp';
import Home from './Home/Home';
import Loader from './Loader/Loader';
import TimeSheetOpsMain from './TimeSheet/TimeSheetOpsMain';
import TimeSheetOpsView from './TimeSheet/TimesheetOpsView/TimeSheetOpsView';
import EstimateHome from './Estimate/EstimateHome';
// import Test from './Test/Test';
// import { createBrowserHistory } from 'history';

import ReactGA from 'react-ga';
import './App.css'
import ttiPolyfill from 'tti-polyfill';
import { deleteCookie, getCookie, setCookie } from './Helper/CookieHelper';
import TenderApproval from './Estimate/Approval/TenderApproval';
import Report from './Estimate/Report/Report';
import ReportDeli from './Estimate/Report/ReportDeli';
import Service from "./Networking/networkutils";
// import TenderImport from './TenderManualImport/TenderImport';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { authProvider } from './Login/authProvider';
import { basicReduxStore } from './Login/reduxStore';
import { LadderLoading } from 'react-loadingg';
// import Dum from './Test/Dum';
// import TaskReport from './Teams/Report/TaskReport';
import UserAllocatedHours from './UserBasedReport/UserAllocatedHours';
import { accessReloadOrRefresh, getPrimaryColor, increseCountTime } from './Helper/CommonThings';
import TenderFeedbackForm from './SecuredWorks/FeedbackRequest/TenderFeedbackForm';
import TenderFeedbackView from './SecuredWorks/FeedbackRequest/TenderFeedbackView';
// import AllTaskReport from './Teams/Report/AllTaskReport';
import ReportHome from './Reports/ReportHome';
import EarnedValue from './Reports/EarnedValue';
import ExtDomainAuth from './Helper/ExtDomainAuth';
import { errorMessage } from './Helper/ErrorMessage';
import LMSAuth from './LMS/LMSAuth';
import PMSAuth from './PMS/PMSAuth';
import MCReport from './MCRReport/MCReport';
import RedirectAlert from './NewPaRaM/RedirectAlert';
import PSRReports from './Reports/PSRReports';
import PageRemoved from './Helper/PageRemoved';
import NewPaRaM from './AuthExternal/NewPaRaM';
import BroadCastOpen from './BroadCast/BroadCastOpen';
import HoeGate6 from './HoeGate6/HoeGate6';
import CricketLive2023 from './BroadCast/CricketLive2023/CricketLive2023';
import TenderSubTasksNewTab from './TenderSubTasks/TenderSubTasksNewTab';
import NewTabTutorials from './Tutorials/NewTabTutorials';
import ExtDomainSetCookie from './Helper/ExtDomainSetCookie';
import OutOfWork from './WorkHours/OutOfWork/OutOfWork';
import BroadCastManage from './BroadCast/BroadCastManage/BroadCastManage';
import SelectedTutorials from './Tutorials/SelectedTutorial';
import LMSRedirect from './LMS/LMSRedirect';
// import CMRisk from './HeadOfs/CMRisk/CMRisk';

var CryptoJS = require("crypto-js");


const trackingId = "UA-156664293-1"; // Replace with your Google Analytics tracking ID

var key = CryptoJS.enc.Utf8.parse('1234567890123456'); // TODO change to something with more entropy  

var count = 0;

if (window.location.hostname.includes('bbparam.com')) {
  ReactGA.initialize(trackingId, { debug: false });

  const user = JSON.parse(getCookie("bb_ts_user"));
  // const token = getCookie("bb_ts_token");
  if (user) {
    ReactGA.set({
      userId: user.id,
      // any data that is relevant to the user session
      // that you would like to track with google analytics
    })
  }
}

ttiPolyfill.getFirstConsistentlyInteractive().then((tti) => {
  ReactGA.timing({
    category: "Load Performace",
    variable: 'Time to Interactive',
    value: tti
  })
});


const callback = list => {
  list.getEntries().forEach(entry => {
    // console.log(entry)
    ReactGA.timing({
      category: "First Meaningful Paint",
      variable: entry.name,
      value: entry.responseEnd
    })
  })
}

var observer = new PerformanceObserver(callback);
observer.observe({
  entryTypes: [
    // 'navigation',
    // 'paint',
    // 'resource',
    // 'mark',
    // 'measure',
    // 'frame',
    // 'longtask'
  ]
})

var fetchDone = {
  one: false,
  two: false
}


// history.listen((location) => {
//     ReactGA.set({ page: location.pathname });
//     ReactGA.pageview(location.pathname)
//   }
// );
// const history = createBrowserHistory();

// function fn60sec() {
//   // console.log('60 sec')
//   var pageView = window.location.pathname;
//   ReactGA.set({ page: pageView, });
//   ReactGA.pageview(pageView);
//   // runs every 60 sec and runs on init.
// }
// fn60sec();
// setInterval(fn60sec, 60 * 1000);

class App extends Component {

  constructor(props) {
    super(props)

    this.state = {
      go: !(window.location.hostname === 'bbparam.com' && !window.location.href.includes("tender/project/feedback") && !window.location.href.includes("edcsport2022") && !window.location.href.includes("bbipl2023") && !window.location.href.includes("sport/24jan")),
    }
    // if (window.location.hostname === 'tasks.bbedcindia.com') {
    //   deleteCookie("bb_ts_token");
    //   deleteCookie("bb_ts_user");
    //   deleteCookie("bb_ts_access");
    //   window.location.href = 'https://bbparam.com'
    // }
  }


  componentDidMount() {
    // console.log("Update Waiting Status:", getServiceWorkerRegistrationGlobal())
    if (localStorage.getItem('theme_mode')) {
      setCookie('theme_mode', localStorage.getItem('theme_mode'), { secure: true, 'max-age': 6048000, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
    }

    // console.log(getCookie("bb_ts_token"), window.location.host)
    if (getCookie("bb_ts_token")) {
      // console.log('logggggg')
      // this.getAccess();
      if (!getCookie("bb_ts_user")) {
        this.getUser(getCookie("bb_ts_token"))
      }
      this.checkToken();
      this.getAccess();
    } else {
      deleteCookie("bb_ts_token");
      deleteCookie("bb_ts_user");
      deleteCookie("bb_ts_access");
      if (window.location.hostname === 'new.bbparam.com') {
        window.location.href = "https://bbparam.com/auth/newparam/"
      }

      if (['qa.param.bbedc.dev', 'sandbox.param.bbedc.dev', 'localhost'].includes(window.location.hostname) && window.location.pathname !== "/login") {
        const locationSearch = window.location?.search
        var hrefUrl = "/login"
        if (locationSearch) {
          const query = new URLSearchParams(locationSearch);
          if (query.get('url')) {
            hrefUrl += "?url=" + (query.get('url'))
          }
        }
        setTimeout(() => window.location.href = hrefUrl , 1000)
      }

      // window.location.reload();
    }

  }

  checkToken() {
    Service.get(`/bb/api/token/valid/check/`, {
      headers: {
        Authorization: "Token " + getCookie("bb_ts_token"),
      },
    })
      .then(res => {
        // console.log((JSON.parse(getCookie("bb_ts_access")).last_updated +"  "+ res.data[0].last_updated))

      })
      .catch(error => {

        // alert(error)
        if (error && (error.status === 401 || (error.response && error.response.status === 401))) {
          // alert("Session Expired - Refreshing Session")
          deleteCookie("bb_ts_token");
          deleteCookie("bb_ts_user");
          // deleteCookie("bb_ts_access");
          accessReloadOrRefresh();
        }
      });
  }

  getAccess() {
    var access = getCookie("bb_ts_access")
    this.getAccessImdt(getCookie("bb_ts_token"), true)
    setInterval(function () {

      // console.log("qwerty")
      if (increseCountTime() < 13) {
        Service.get(`/bb/api/token/access/`, {
          headers: {
            Authorization: "Token " + getCookie("bb_ts_token"),
          },
        })
          .then(res => {
            if (res.data && res.data[0] && JSON.parse(access)) {
              if (JSON.parse(access).last_updated !== res.data[0].last_updated) {
                setCookie('bb_ts_access', JSON.stringify(res.data[0]), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
                accessReloadOrRefresh();
              }
              if (!getCookie("bb_ts_access")) {
                setCookie('bb_ts_access', JSON.stringify(res.data[0]), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
                accessReloadOrRefresh();
              }
            } else if (res.data && res.data[0] && !getCookie("bb_ts_access")) {
              setCookie('bb_ts_access', JSON.stringify(res.data[0]), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
              accessReloadOrRefresh();
            } else {
              setCookie('bb_ts_access', JSON.stringify({}), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
            }
          })
          .catch(error => {
            console.log("access api failed")
          });
      }
    }, 300000);
  }

  getAccessImdt(token, bypass) {
    // var access = getCookie("bb_ts_access")
    Service.get(`/bb/api/token/access/`, {
      headers: {
        Authorization: "Token " + token,
      },
    })
      .then(res => {
        if (res.data && res.data[0]) {
          console.log(this.props.location?.search)
          if (window.location.hostname === 'bbparam.com') {
            if (res.data[0]?.access?.l111a?.l111a_g) {
              window.location.href = window.location.protocol + "//new." + window.location.hostname + window.location.pathname + (window.location?.search ? window.location.search : "") + (window.location?.hash ? window.location.hash : "")
            }
          }
          if (getCookie("bb_ts_access")) {
            if (JSON.parse(getCookie("bb_ts_access")).last_updated !== res.data[0].last_updated) {
              setCookie('bb_ts_access', JSON.stringify(res.data[0]), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
              fetchDone.one = true
            }
          } else {
            setCookie('bb_ts_access', JSON.stringify(res.data[0]), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
            fetchDone.one = true
          }

        } else {
          setCookie('bb_ts_access', JSON.stringify({}), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });

        }
        if (fetchDone.one && (fetchDone.two || bypass)) {
          accessReloadOrRefresh();
        }
      })
      .catch(error => {
        console.log(error)
        setCookie('bb_ts_access', JSON.stringify({}), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
      });

  }

  getUser(token) {
    Service.get(`/rest-auth/user/`, {
      headers: {
        Authorization: "Token " + token
      },
    })
      .then(res => {
        // console.log(res.data)
        fetchDone.two = true

        setCookie('bb_ts_user', JSON.stringify(res.data), { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', });
        // doneApi = true;
        // localStorage.setItem("load_got_user", true);
        if (fetchDone.one && fetchDone.two) {
          accessReloadOrRefresh();
        }

      })
      .catch(error => {
        // alert(erro
        errorMessage(this.props, error)

      });
  }

  getTokenFromDj(data) {

    Service.patch('/bb/api/generate/token/token_update/', {
      data: {
        id_token: encrypt("P1a1R1a1M" + data.jwtIdToken + "P1a1R1a1M", key),
      }
    })
      .then(res => {
        // console.log(res.data)
        if (res.data && res.data.token) {
          if (window.location.pathname !== '/') {
            this.getAccessImdt(res.data.token)
            this.getUser(res.data.token)
          } else if (window.location.pathname === '/') {
            setTimeout(() => { this.setState({ go: true }) }, 100);
          }

          setCookie('bb_ts_token', res.data.token, { secure: true, 'max-age': 604800, 'domain': window.location.host, 'samesite': 'None', 'path': '/', },);

        } else {
          alert("We haven't added you to PaRaM.")
        }

      })
      .catch(error => {
        console.log(error)
      });
  }


  render() {

    return (
      <div>
        <RedirectAlert />
        {
          (!this.state.go) ? (
            <AzureAD provider={authProvider} reduxStore={basicReduxStore} forceLogin={!getCookie("bb_ts_token")} >
              {
                ({ login, logout, authenticationState, error, accountInfo }) => {

                  switch (authenticationState) {
                    case AuthenticationState.Authenticated:
                      // console.log(accountInfo)

                      if (count === 1) {

                        if (!getCookie("bb_ts_token")) {
                          this.getTokenFromDj(accountInfo)
                        } else {
                          this.setState({ go: true })
                        }
                        // this.getTokenFromDj(accountInfo)
                      }
                      count++

                      return (
                        <>
                          {(this.state.go) ? (
                            <AppRoute />
                          ) : (
                            null
                          )}

                        </>

                      );
                    case AuthenticationState.Unauthenticated:
                      console.log(error)

                      deleteCookie("bb_ts_token");
                      deleteCookie("bb_ts_user");
                      deleteCookie("bb_ts_access");

                      // if (window.location.pathname !== '/') {
                      //   window.location.href = '/'
                      // }

                      return (<p>Authenticating...</p>);
                    case AuthenticationState.InProgress:
                      return (<p>Authenticating...</p>);
                    default:
                      setTimeout(() => {
                        if (authenticationState !== AuthenticationState.Authenticated) {
                          window.location.reload()
                        }
                      }, 3000);
                      return (<p>Authenticating...</p>);
                  }
                }
              }

            </AzureAD>
          ) : (
            <AppRoute />
          )
        }
        {(this.state.go) ? (
          null
        ) : (
          <LadderLoading color={getPrimaryColor()} />
        )}

      </div>
    )
  }
}

function encrypt(msgString, key) {
  // msgString is expected to be Utf8 encoded
  var iv = CryptoJS.lib.WordArray.random(16);
  var encrypted = CryptoJS.AES.encrypt(msgString, key, {
    iv: iv
  });
  return iv.concat(encrypted.ciphertext).toString(CryptoJS.enc.Base64);
}

function AppRoute() {
  return (
    <BrowserRouter basename="/" >

      <Switch>
        <Route exact path="/" component={Loader} />
        <Route path="/login" component={Login} />
        {/* <Route path="/signup" component={SignUp} /> */}
        {/* <Route path="/timesheet" component={TimeSheetDialog} /> */}
        <Route path="/home" component={Home} />
        <Route path="/approval/:appid" component={TimeSheetOpsMain} />
        <Route path="/approvalv/:appid" component={TimeSheetOpsView} />
        <Route path="/tender/approval/:appid" component={TenderApproval} />
        <Route path="/tender/project/feedback/:appid" component={TenderFeedbackForm} />
        <Route path="/tender/project/viewfeedback/:appid" component={TenderFeedbackView} />
        <Route path="/estimate" component={EstimateHome} />
        <Route path="/tender/mini/report/:appid" component={Report} />
        <Route path="/ds/mini/report/:appid/:tenid" component={ReportDeli} />
        <Route path="/project/manual/:appid/:tenid" component={PageRemoved} />
        <Route path="/timesheet/report" component={PageRemoved} />
        <Route path="/user/taskallocation" component={UserAllocatedHours} />
        <Route exact path="/reports" component={ReportHome} />
        <Route path="/report/alltask/:type" component={PageRemoved} />
        <Route path="/report/mcr/:pid" component={MCReport} />
        <Route path="/report/evp/:pid" component={EarnedValue} />
        <Route path="/report/psr/:pid/:audit" component={PSRReports} />
        <Route path="/ext/auth/" component={ExtDomainAuth} />
        <Route path="/ext/domain/setcookie/" component={ExtDomainSetCookie} />
        <Route path="/lms/auth/" component={LMSAuth} />
        <Route path="/pms/auth/" component={PMSAuth} />
        <Route path="/auth/newparam/" component={NewPaRaM} />
        <Route path="/tutorials/" component={NewTabTutorials} />
        <Route path="/tutorial/:itemid" component={SelectedTutorials} />
        <Route path="/edcsports2024/" component={BroadCastOpen} />
        {/* <Route path="/bbipl2023/" component={BroadCastOpen} /> */}
        {/* <Route path="/sbl2024/" component={BroadCastOpen} /> */}
        <Route path="/broadcast/manage/" component={BroadCastManage} />
        <Route path="/hoegate6/" component={HoeGate6} />
        <Route path="/sport/24jan" component={CricketLive2023} />
        <Route path="/tendersubtasks/:taskid" component={TenderSubTasksNewTab} />
        <Route path="/outofworks" component={OutOfWork} />
        <Route path="/lms" component={LMSRedirect} />
        {/* <Route path="/form1" component={Section2Form} />
        <Route path="/form2" component={Section3Form} />
        <Route path="/form3" component={Section4Form} /> */}
        {/* <Route path="/test" component={CMRisk} /> */}
        {/* <Route path="/testdrawer" component={DrawerTest} /> */}
        {/* <Route path="/dum/dum" component={EarnedValue} /> */}
        <Route path="*" component={PageRemoved} />
      </Switch>

    </BrowserRouter>
  );
}

export default (App);
